import axios from 'axios.js';

export const GET_CATEGORY_LIST = 'GET_CATEGORY_LIST';
export const GET_CUSTOMER_DROPDOWN = 'GET_CUSTOMER_DROPDOWN';
export const GET_EMPLOYEE_DROPDOWN = 'GET_EMPLOYEE_DROPDOWN';
export const GET_SUPPLIER_DROPDOWN = 'GET_SUPPLIER_DROPDOWN';

export const getCategoryList = () => (dispatch) => {
  axios.get('/category/list').then((res) => {
    dispatch({
      type: GET_CATEGORY_LIST,
      payload: res.data.category,
    });
  });
};

export const getCustomerDropdown = (data) => (dispatch) => {
  axios.post('/user/dropdownByRole', data).then((res) => {
    dispatch({
      type: GET_CUSTOMER_DROPDOWN,
      payload: res.data,
    });
  });
};
export const getEmployeeDropdown = (data) => (dispatch) => {
  axios.post('/user/dropdownByRole', data).then((res) => {
    dispatch({
      type: GET_EMPLOYEE_DROPDOWN,
      payload: res.data,
    });
  });
};
export const getSupplierDropdown = (data) => (dispatch) => {
  axios.post('/user/dropdownByRole', data).then((res) => {
    dispatch({
      type: GET_SUPPLIER_DROPDOWN,
      payload: res.data,
    });
  });
};
