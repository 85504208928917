import { ADD_PRODUCT, ADD_PRODUCT_BRAND, DELETE_PRODUCT, DELETE_PRODUCT_BRAND, GET_ARCHIVE_LIST, GET_PRODUCT_DROPDOWN, GET_PRODUCT_LIST, MAKE_ARCHIVE_PRODUCT, MULTIPLE_PRODUCT_DELETE, UPDATE_PRODUCT_DETAILS } from '../actions/ProductActions';

const initialState = {
  productDropdown: [],
  productList: [],
  archiveList: [],
};

const ProductReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCT_DROPDOWN: {
      return { ...state, productDropdown: action.payload };
    }
    case GET_PRODUCT_LIST: {
      return { ...state, productList: action.payload };
    }
    case ADD_PRODUCT: {
      return { ...state };
    }
    case UPDATE_PRODUCT_DETAILS: {
      return { ...state };
    }
    case DELETE_PRODUCT: {
      return { ...state };
    }
    case MULTIPLE_PRODUCT_DELETE: {
      return { ...state };
    }
    case MAKE_ARCHIVE_PRODUCT: {
      return { ...state };
    }
    case ADD_PRODUCT_BRAND: {
      return { ...state };
    }
    case DELETE_PRODUCT_BRAND: {
      return { ...state };
    }
    case GET_ARCHIVE_LIST: {
      return { ...state, archiveList: action.payload };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default ProductReducer;
