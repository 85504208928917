import React, { useState } from 'react';

export const TitleContext = React.createContext();

export const TitleProvider = ({ children }) => {
  const [title, setTitle] = useState('Dashboard');
  const [action, setAction] = useState('List');
  const [module, setModule] = useState('dashboard');
  const [drawer, setDrawer] = useState(false);

  return (
    <TitleContext.Provider
      value={{ title, setTitle, action, setAction, module, setModule, drawer, setDrawer }}
    >
      {children}
    </TitleContext.Provider>
  );
};

export default TitleProvider;
