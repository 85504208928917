import { GET_STOCK_LIST } from "../actions/StockActions";

const initialState = {
  stockList: [],
};

const StockReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_STOCK_LIST: {
      return { ...state, stockList: action.payload };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default StockReducer;
