import { combineReducers } from 'redux';

import UiReducer from './UiReducer';
import AmcReducer from './AmcReducer';
import UsersReducer from './UsersReducer';
import ProductReducer from './ProductReducer';
import ServiceReducer from './ServiceReducer';
import StockReducer from './StockReducer';
import QuotationsReducer from './QuotationsReducer';
import PurchaseReducer from './PurchaseReducer';
import CategoryReducer from './CategoryReducer';
import TaxReducer from './TaxReducer';
import SalesReducer from './SalesReducer';
import complaintReducer from './ComplaintReducer';
import storeserviceReducer from './StoreserviceReducer';
import taskReducer from './TaskReducer';
import incomeReducer from './IncomeReducer';
import expenseReducer from './ExpenseReducer';
import reportReducer from './ReportReducer';
import emailTemplateReducer from './EmailTemplateReducer';
import settingsReducer from './SettingsReducer';
import accessRightsReducer from './AccessRightsReducer';
import EcommerceReducer from './EcommerceReducer';
import NavigationReducer from './NavigationReducer';
import NotificationReducer from './NotificationReducer';
import DashboardReducer from './DashboardReducer';
import WizardReducer from './WizardReducer';

const RootReducer = combineReducers({
  ui: UiReducer,
  users: UsersReducer,
  amc: AmcReducer,
  purchase: PurchaseReducer,
  category: CategoryReducer,
  tax: TaxReducer,
  product: ProductReducer,
  service: ServiceReducer,
  stock: StockReducer,
  sales: SalesReducer,
  complaint: complaintReducer,
  storeservice: storeserviceReducer,
  task: taskReducer,
  income: incomeReducer,
  expense: expenseReducer,
  report: reportReducer,
  emailTemplate: emailTemplateReducer,
  settings: settingsReducer,
  accessRights: accessRightsReducer,
  quotation: QuotationsReducer,
  notifications: NotificationReducer,
  dashboard: DashboardReducer,
  navigations: NavigationReducer,
  ecommerce: EcommerceReducer,
  Wizard: WizardReducer,
});

export default RootReducer;
