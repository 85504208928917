import {
  GET_CATEGORY_LIST,
  GET_CUSTOMER_DROPDOWN,
  GET_EMPLOYEE_DROPDOWN,
  GET_SUPPLIER_DROPDOWN,
} from '../actions/CategoryActions';

const initialState = {
  categoryList: [],
  customerDropdown: [],
  employeeDropdown: [],
  supplierDropdown: [],
};

const CategoryReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_CATEGORY_LIST: {
      return { ...state, categoryList: action.payload ? action.payload[0] : action.payload };
    }
    case GET_CUSTOMER_DROPDOWN: {
      return { ...state, customerDropdown: action.payload };
    }
    case GET_EMPLOYEE_DROPDOWN: {
      return { ...state, employeeDropdown: action.payload };
    }
    case GET_SUPPLIER_DROPDOWN: {
      return { ...state, supplierDropdown: action.payload };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default CategoryReducer;
