import React, { Fragment, useState } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
// import Confirm from './Confirm';
import Success from './Success';
import formValidation from '../Helper/formValidation';
import { Button, Divider, Grid, ListItem, ListItemText, Snackbar, styled } from '@mui/material';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { wizardApiTest, wizardInstall } from 'app/redux/actions/WizardActions';
import { Store } from 'app/redux/Store';
import { SnackbarProvider } from 'notistack';
import Thired from './Thired';
import { List } from 'echarts';

// Step titles
const labels = ['Purchase Information', 'Database Setup', 'Login Details', 'Finish'];
// const labels = ['API', 'Purchase Information', 'Database Setup', 'Login Datils', 'Finish'];
const initialValues = {
  domain_name: '',
  purchase_key: '',
  purchase_email: '',
  db_name: '',
  db_username: '',
  db_pass: '',
  db_host: 'localhost',
  db_port: '3306',
  email: '',
  password: '',
  confirm_password: '',
};

const fieldsValidation = {
  domain_name: {
    error: '',
    validate: '',
    minLength: 2,
    maxLength: 20,
  },
  purchase_key: {
    error: '',
  },
  purchase_email: {
    error: '',
    validate: 'email',
  },
  db_name: { error: '', validate: '', minLength: 2, maxLength: 20 },
  db_username: {
    error: '',
  },
  db_pass: {
    error: '',
  },
  db_host: {
    error: '',
  },
  db_port: {
    error: 'please enter valid port number',
    validate: 'num',
  },
  email: {
    error: '',
  },
  password: {
    error: '',
    validate: 'pass',
    minLength: 8,
    maxLength: 20,
  },
  confirm_password: {
    error: '',
    validate: 'confirm',
  },
};

const Notetitle = styled('div')(() => ({
  fontSize: '18px',
  fontFamily: 'poppins',
  marginBottom: '10px',
}));

const Notepoint = styled('div')(() => ({
  fontSize: '14px',
  fontFamily: 'poppins',
  marginBottom: '5px',
}));

const Notepointone = styled('div')(() => ({
  fontSize: '14px',
  fontFamily: 'poppins',
  marginTop: '15px',
  marginBottom: '5px',
}));

// const TestAPI = () => {
//   const dispatch = useDispatch();

//   const apiTest = () => {
//     dispatch(wizardApiTest());
//   };

//   return (
//     <div>
//       <Grid container spacing={2} noValidate>
//         <Grid item xs={12} sm={6}></Grid>
//         <Grid item xs={12} sm={6}></Grid>

//         <Grid item xs={12} sm={6}></Grid>
//         <Grid item xs={12} sm={6}></Grid>

//         <Grid item xs={12} sm={6}></Grid>
//         <Grid item xs={12} sm={6}></Grid>

//         <Grid item xs={12} sm={6}></Grid>
//         <Grid item xs={12} sm={6}></Grid>

//         <Grid item xs={12} sm={6}></Grid>
//         <Grid item xs={12} sm={6}></Grid>

//         <Grid item xs={12} sm={12} lg={12} xl={12}>
//           <Button sx={{ ml: 30 }} variant="contained" color="primary" onClick={() => apiTest()}>
//             Test API
//           </Button>
//         </Grid>

//         <Grid item xs={12} sm={6}></Grid>
//         <Grid item xs={12} sm={6}></Grid>
//         <Grid item xs={12} sm={6}></Grid>
//         <Grid item xs={12} sm={6}></Grid>
//         <Grid item xs={12} sm={6}></Grid>
//         <Grid item xs={12} sm={6}></Grid>
//       </Grid>
//     </div>
//   );
// };

const StepForm = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const dispatch = useDispatch();
  // Proceed to next step
  const handleNext = () => setActiveStep((prev) => prev + 1);
  // Go back to prev step
  const handleBack = () => setActiveStep((prev) => prev - 1);

  // Handle form change
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Set values
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));

    // set errors
    const error = formValidation(name, value, fieldsValidation) || '';

    setFormErrors({
      [name]: error,
    });
  };
  const wizardData = useSelector((state) => state.Wizard);

  const handleSubmit = () => {
    let formData = new FormData();
    console.log(formValues.db_pass);
    formData.append('domain_name', formValues.domain_name);
    formData.append('purchase_key', formValues.purchase_key);
    formData.append('purchase_email', formValues.purchase_email);
    formData.append('db_host', formValues.db_host);
    formData.append('db_port', formValues.db_port);
    formData.append('db_name', formValues.db_name);
    formData.append('db_username', formValues.db_username);
    if (formValues.db_pass !== '') {
      formData.append('db_pass', formValues.db_pass);
    }
    formData.append('email', formValues.email);
    formData.append('password', formValues.password);
    dispatch(wizardInstall(formData));
  };

  const Confirm = () => {
    return (
      <>
        <div>
          <Notetitle>Please Note :</Notetitle>
          <Divider />

          <Notepointone>1. Make sure you added correct Information in all step.</Notepointone>
          <Notepoint>2. It may take couple of minutes to set-up</Notepoint>
          <Notepoint>3. Do not refresh this page once you click on install button</Notepoint>
          <Notepoint>
            4. Installation wizard will acknowledge you once the installation is successful
          </Notepoint>
          <Notepoint>5. Click install to complete the installation</Notepoint>
        </div>
        <div style={{ display: 'flex', marginTop: 50, justifyContent: 'flex-end' }}>
          <Button variant="contained" onClick={handleBack}>
            Back
          </Button>
          <Button
            style={{ marginLeft: 20 }}
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
          >
            Install Now
          </Button>
        </div>
      </>
    );
  };

  const handleSteps = (step) => {
    switch (step) {
      // case 0:
      //   return (
      //     <Provider store={Store}>
      //       <SnackbarProvider>
      //         <Snackbar />
      //         <TestAPI />
      //         <div style={{ display: 'flex', marginTop: 50, justifyContent: 'flex-end' }}>
      //           <Button
      //             variant="contained"
      //             color="primary"
      //             disabled={wizardData.apiStatus !== true ? false : true}
      //             onClick={handleNext}
      //           >
      //             Next
      //           </Button>
      //         </div>
      //       </SnackbarProvider>
      //     </Provider>
      //   );
      case 0:
        return (
          <FirstStep
            handleNext={handleNext}
            handleChange={handleChange}
            values={formValues}
            formErrors={formErrors}
          />
        );
      case 1:
        return (
          <SecondStep
            handleNext={handleNext}
            handleBack={handleBack}
            handleChange={handleChange}
            values={formValues}
            formErrors={formErrors}
          />
        );
      case 2:
        return (
          <Thired
            handleNext={handleNext}
            handleBack={handleBack}
            handleChange={handleChange}
            values={formValues}
            formErrors={formErrors}
          />
        );
      case 3:
        return <Confirm />;
      default:
        break;
    }
  };

  return (
    <>
      {activeStep === labels.length ? (
        // Last Component
        <Success values={formValues} />
      ) : (
        <>
          {/* <Box style={{ margin: '0px 0 0px' }}>
            <Typography variant="h4" align="center">
              Installation Wizard
            </Typography>
          </Box> */}
          <Stepper activeStep={activeStep} style={{ margin: '30px 0 15px' }} alternativeLabel>
            {labels.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {handleSteps(activeStep)}
        </>
      )}
    </>
  );
};

export default StepForm;
