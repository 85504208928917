import { GET_EMAIL_TEMPLATE, UPDATE_TEMPLATE } from "../actions/EmailTemplateActions";

const initialState = {
  emailTemplateList: [],
};

const EmailTemplateReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_EMAIL_TEMPLATE: {
      return { ...state, emailTemplateList: action.payload };
    }
    case UPDATE_TEMPLATE: {
      return { ...state };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default EmailTemplateReducer;
