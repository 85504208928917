export const SNACKBAR_OPEN = 'SNACKBAR_OPEN';
export const SNACKBAR_CLEAR = 'SNACKBAR_CLEAR';

export const showSnackbar = (message, variant) => {
  return (dispatch) => {
    dispatch({ type: 'SNACKBAR_OPEN', message, variant });
  };
};

export const clearSnackbar = () => {
  return (dispatch) => {
    dispatch({ type: 'SNACKBAR_CLEAR' });
  };
};
