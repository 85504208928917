import axios from 'axios.js';

export const DASHBOARD_LIST = 'DASHBOARD_LIST';

export const getDasshboardData = () => (dispatch) => {
  axios.get('/dashboard/data').then((res) => {
    dispatch({
      type: DASHBOARD_LIST,
      payload: res.data,
    });
  });
};
