import axios from 'axios.js';
import { showSnackbar } from './UiActions';

export const GET_INCOME_LIST = 'GET_INCOME_LIST';
export const ADD_INCOME = 'ADD_INCOME';
export const UPDATE_INCOME = 'UPDATE_INCOME';
export const GET_INCOME_REPORT = 'GET_INCOME_REPORT';
export const DELETE_INCOME = 'DELETE_INCOME';

export const getIncomeList = () => (dispatch) => {
  axios.get('/income/list').then((res) => {
    dispatch({
      type: GET_INCOME_LIST,
      payload: res.data.data,
    });
  });
};

export const addIncome = (data) => (dispatch) => {
  axios
    .post('/income/store', data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch({
        type: ADD_INCOME,
        payload: response.data,
      });
      dispatch(getIncomeList());
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
    });
};

export const updateIncome = (data) => (dispatch) => {
  axios
    .post('/income/update', data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch({
        type: UPDATE_INCOME,
        payload: response.data,
      });
      dispatch(getIncomeList());
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
    });
};

export const getIncomeReport = (data) => (dispatch) => {
  axios
    .post('/income/monthly/report', data)
    .then((response) => {
      // dispatch(showSnackbar(response.data.message, 'success'));
      dispatch({
        type: GET_INCOME_REPORT,
        payload: response.data.data,
      });
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
    });
};

export const deleteIncome = (data) => (dispatch) => {
  axios
    .post('/income/delete', data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch({
        type: DELETE_INCOME,
        payload: response.data.data,
      });
      dispatch(getIncomeList());
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
    });
};
