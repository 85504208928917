import { INSTALL_WIZARD, TEST_WIZARD } from '../actions/WizardActions';

const initialState = {
  apiStatus: true,
};

const WizardReducer = function (state = initialState, action) {
  switch (action.type) {
    case TEST_WIZARD: {
      return { ...state, apiStatus: action.payload };
    }
    case INSTALL_WIZARD: {
      return { ...state };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default WizardReducer;
