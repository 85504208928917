import { Box, Button, styled } from '@mui/material';
import { TitleContext } from 'app/contexts/titleContext';
import i18n from 'i18n';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const FlexBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const JustifyBox = styled(FlexBox)(() => ({
  maxWidth: 320,
  flexDirection: 'column',
  justifyContent: 'center',
}));

const IMG = styled('img')(() => ({
  width: '100%',
  marginTop: '65px'
  // marginBottom: '32px',
}));

const Contant = styled('div')(() => ({
  fontFamily: 'poppins',
  fontSize: '17px',
  fontWeight: 600,
  padding: '20px 0px 20px 0px'
}));

const NotFoundRoot = styled(FlexBox)(() => ({
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  // height: '100vh !important',
}));

const BootstrapButton = styled(Button)({
  // backgroundColor: '#01C681',
  backgroundColor: '#103C64',
  fontFamily: 'poppins',
  fontSize: '14px',
  fontWeight: '400',
  '&:hover': {
    // backgroundColor: '#01C681',
    backgroundColor: '#103C64',
  },
  '&:active': {
    // backgroundColor: '#01C681',
    backgroundColor: '#103C64',
  },
});

const NotFound = (Props) => {
  const navigate = useNavigate();
  const { setTitle, setAction } = useContext(TitleContext);


  useEffect(() => {
    setAction("List")
    setTitle(Props.data);
  }, []);

  return (
    <NotFoundRoot>
      <JustifyBox>
        <IMG src="/assets/images/illustrations/403.svg" alt="" />
        <Contant>
          {i18n.t("Oops! You don't have the access for this page contact to administrator.")}
        </Contant>
        <BootstrapButton
          color="primary"
          variant="contained"
          sx={{ textTransform: 'capitalize' }}
          onClick={() => navigate(-1)}
        >
          {i18n.t("Go Back")}
        </BootstrapButton>
      </JustifyBox>
    </NotFoundRoot>
  );
};

export default NotFound;
