import axios from 'axios.js';
import { showSnackbar } from './UiActions';

export const GET_AMC_LIST = 'GET_AMC_LIST';
export const ADD_AMC = 'ADD_AMC';
export const UPDATE_AMC = 'UPDATE_AMC';
export const DELETE_AMC = 'DELETE_AMC';
export const MULTIPLE_AMC_DELETE = 'MULTIPLE_AMC_DELETE';

export const getAmcList = () => (dispatch) => {
  axios.get('/amc/list').then((res) => {
    dispatch({
      type: GET_AMC_LIST,
      payload: res.data,
    });
  });
};

// export const addAmc = (data) => (dispatch) => {
//   axios
//     .post('/amc/store', data)
//     .then((response) => {
//       dispatch(showSnackbar(response.data.message, 'success'));
//       dispatch({
//         type: ADD_AMC,
//         payload: response.data,
//       });
//       dispatch(getAmcList());
//       return Promise.resolve();
//     })
//     .catch((error) => {
//       dispatch(showSnackbar(error.message, 'error'));
//       return Promise.reject();
//     });
// };

export const addAmc = (data) => async (dispatch) => {
  try {
    const response = await axios.post("/amc/store", data);
    dispatch(showSnackbar(response.data.message, 'success'));
    dispatch({
      type: ADD_AMC,
      payload: response.data,
    });
    if (response.status === 200) {
      dispatch(getAmcList());
    }

    return response;
  } catch (error) {
    dispatch(
      showSnackbar(error.response?.data?.message || error.message, "error")
    );
    return error.response?.data?.message || error.message;
  }
};

export const updateAmc = (data) => (dispatch) => {
  axios
    .post('/amc/update', data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch({
        type: ADD_AMC,
        payload: response.data,
      });
      dispatch(getAmcList());
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
      return Promise.reject();
    });
};

export const deleteAmc = (data) => (dispatch) => {
  axios
    .post('/amc/delete', data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch({
        type: DELETE_AMC,
        payload: response.data,
      });
      dispatch(getAmcList());
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
      return Promise.reject();
    });
};

export const multipleAmcDelete = (data) => (dispatch) => {
  axios
    .post('/amc/deleteMultiAmc', data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch({
        type: MULTIPLE_AMC_DELETE,
        payload: response.data,
      });
      dispatch(getAmcList());
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
    });
};

export const approveEmployeeStatus = (data) => (dispatch) => {
  axios
    .post('/amc/approveEmployeeStatus', data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch(getAmcList());
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
      return Promise.reject();
    });
};

export const rejectEmployeeStatus = (data) => (dispatch) => {
  axios
    .post('/amc/rejectEmployeeStatus', data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch(getAmcList());
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
      return Promise.reject();
    });
};
