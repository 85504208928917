import {
  ADD_SALES,
  DELETE_SALES,
  GET_SALES_LIST,
  LOADING_END_SELL,
  LOADING_START_SELL,
  MULTIPLE_DELETE_SALES,
  UPDATE_SALES,
} from '../actions/SalesActions';

const initialState = {
  salesList: [],
  loading: false,
};

const SalesReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_SALES_LIST: {
      return { ...state, salesList: action.payload };
    }
    case ADD_SALES: {
      return { ...state };
    }
    case UPDATE_SALES: {
      return { ...state };
    }
    case DELETE_SALES: {
      return { ...state };
    }
    case MULTIPLE_DELETE_SALES: {
      return { ...state };
    }
    case LOADING_START_SELL:
      return {
        ...state,
        loading: true,
      };
    case LOADING_END_SELL:
      return {
        ...state,
        loading: false,
      };
    default: {
      return {
        ...state,
      };
    }
  }
};

export default SalesReducer;
