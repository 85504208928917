import React, { useEffect, useState } from 'react'
import Wizard from './wizard'
import { Button, styled } from '@mui/material';
import axios from './axios';
import Swal from 'sweetalert2';
import App from 'app/App';

const ApiTest = () => {

    const [status, setStatus] = useState(null)

    useEffect(() => {
        axios.get('/test/backend').then((res) => {

            axios.get('/install/check').then((res) => {
                if (res.data.data.install_status === false) {
                    localStorage.setItem('install_status', 0)
                    setStatus(0)
                } else {
                    localStorage.setItem('install_status', 1)
                    setStatus(1)
                }

            }).catch((error) => {
                localStorage.setItem('install_status', 0)
                setStatus(0)
            });

        }).catch(err => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: "Please set your domain url in .env file and try again",
            })
        });
    }, [status])

    return (
        <div>
            {localStorage.getItem('install_status') == 1 ? (
                <App />
            ) : localStorage.getItem('install_status') == 0 ? (
                <Wizard />
            ) : (<></>)}
        </div>

    );
};

export default ApiTest;
