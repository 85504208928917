import { ADD_STORESERVICE, DELETE_STORESERVICE, GET_STORESERVICE_LIST, UPDATE_STORESERVICE } from "../actions/Storeservice";


const initialState = {
  complaintList: [],
};

const ComplaintReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_STORESERVICE_LIST: {
      return { ...state, complaintList: action.payload };
    }
    case ADD_STORESERVICE: {
      return { ...state, };
    }
    case UPDATE_STORESERVICE: {
      return { ...state, };
    }
    case DELETE_STORESERVICE: {
      return { ...state, };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default ComplaintReducer;
