import {
  ADD_QUOTATION,
  DELETE_QUOTATION,
  EDIT_QUOTATION,
  GET_QUOTATIONS_LIST,
  LOADING_END_QUOTATION,
  LOADING_START_QUOTATION,
  MULTIPLE_DELETE_QUOTATION,
} from '../actions/QuotationActions';

const initialState = {
  quotationList: [],
  loading: false,
};

const QuotationsReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_QUOTATIONS_LIST: {
      return { ...state, quotationList: action.payload };
    }
    case ADD_QUOTATION: {
      return { ...state };
    }
    case EDIT_QUOTATION: {
      return { ...state };
    }
    case DELETE_QUOTATION: {
      return { ...state };
    }
    case MULTIPLE_DELETE_QUOTATION: {
      return { ...state };
    }
    case LOADING_START_QUOTATION:
      return {
        ...state,
        loading: true,
      };
    case LOADING_END_QUOTATION:
      return {
        ...state,
        loading: false,
      };
    default: {
      return {
        ...state,
      };
    }
  }
};

export default QuotationsReducer;
