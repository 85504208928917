import axios from 'axios.js';

export const GET_STOCK_LIST = 'GET_STOCK_LIST';

export const getstockList = () => (dispatch) => {
  axios.get('/stock/list').then((res) => {
    dispatch({
      type: GET_STOCK_LIST,
      payload: res.data.data,
    });
  });
};

