import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import RootReducer from './reducers/RootReducer';

const persistConfig = {
  key: 'main-root',
  storage,
  stateReconciler: hardSet,
};

let devtools = (x) => x;

if (
  process &&
  process.env.NODE_ENV !== 'production' &&
  process.browser &&
  window.__REDUX_DEVTOOLS_EXTENSION__
) {
  devtools = window.__REDUX_DEVTOOLS_EXTENSION__();
}

const persistedReducer = persistReducer(persistConfig, RootReducer);
const middleware = applyMiddleware(thunk);
const Store = createStore(persistedReducer, middleware);

const persistor = persistStore(Store);
export { persistor, Store };
