import { ADD_SERVICES, GET_SERVICE_LIST, MULTIPLE_SERVICE_DELETE, SERVICE_USER, UPDATE_SERVICES } from "../actions/ServiceActions";

const initialState = {
  serviceList: [],
};

const ServiceReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_SERVICE_LIST: {
      return { ...state, serviceList: action.payload };
    }
    case ADD_SERVICES: {
      return { ...state, };
    }
    case UPDATE_SERVICES: {
      return { ...state, };
    }
    case SERVICE_USER: {
      return { ...state, };
    }
    case MULTIPLE_SERVICE_DELETE: {
      return { ...state, };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default ServiceReducer;
