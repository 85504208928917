import axios from 'axios.js';
import { showSnackbar } from './UiActions';

export const GET_ROLE_LIST = 'GET_ROLE_LIST';
export const GET_MODULE_LIST = 'GET_MODULE_LIST';
export const UPDATE_ACCESSRIGHT = 'UPDATE_ACCESSRIGHT';

export const getRoleList = () => (dispatch) => {
  axios.get('/role/list').then((res) => {
    dispatch({
      type: GET_ROLE_LIST,
      payload: res.data.data,
    });
  });
};

export const getModuleList = () => (dispatch) => {
  axios.get('/module/list').then((res) => {
    dispatch({
      type: GET_MODULE_LIST,
      payload: res.data.data,
    });
  });
};

export const updateaccessRights = (data) => (dispatch) => {
  axios
    .post('/role/update', data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch({
        type: UPDATE_ACCESSRIGHT,
        payload: response.data,
      });
      dispatch(getRoleList());
      dispatch(getModuleList());
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
      return Promise.reject();
    });
};
