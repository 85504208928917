import axios from 'axios.js';
import { showSnackbar } from './UiActions';
import Swal from 'sweetalert2';
// import { env } from '../../../../env';
export const TEST_WIZARD = 'TEST_WIZARD';
export const INSTALL_WIZARD = 'INSTALL_WIZARD';

// const dotenv = require('dotenv');

export const apiTest = () => (dispatch) => {
  axios.get('/test/backend').then((res) => {
    console.log(res.data.error);
    dispatch({
      type: TEST_WIZARD,
      payload: res.data.error,
    });
  });
};

export const wizardInstall = (data) => (dispatch) => {
  axios.post('/install', data).then((response) => {
    dispatch({
      type: INSTALL_WIZARD,
      payload: response.data,
    })
    localStorage.setItem('install_status', 1)
    window.location.reload()
  })
    .catch((error) => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.response.data.message,
      })
    });
};
