import { DASHBOARD_LIST } from '../actions/DashboardActions';

const initialState = {
  dahboardList: [],
};

const DashboardReducer = function (state = initialState, action) {
  switch (action.type) {
    case DASHBOARD_LIST: {
      return { ...state, dahboardList: action.payload };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default DashboardReducer;
