import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const Quotation = Loadable(lazy(() => import('./index.js')));

const quotationRoutes = [
  { path: '/quotation/list', element: <Quotation />, auth: authRoles.admin },
];

export default quotationRoutes;
