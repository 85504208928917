import axios from 'axios.js';
import { showSnackbar } from './UiActions';

export const GET_PURCHASE_LIST = 'GET_PURCHASE_LIST';
export const ADD_PURCHASE = 'ADD_PURCHASE';
export const UPDATE_PURCHASE = 'UPDATE_PURCHASE';
export const DELETE_PURCHASE = 'DELETE_PURCHASE';
export const MULTIPLE_PURCHASE_DELETE = 'MULTIPLE_PURCHASE_DELETE';

export const getPurchaseList = () => (dispatch) => {
  axios.get('/purchase/list').then((res) => {
    dispatch({
      type: GET_PURCHASE_LIST,
      payload: res.data,
    });
  });
};

export const addPurchase = (data) => (dispatch) => {
  axios
    .post('/purchase/store', data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch({
        type: ADD_PURCHASE,
        payload: response.data,
      });
      dispatch(getPurchaseList());
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
      return Promise.reject();
    });
};

export const updatePurchase = (data) => (dispatch) => {
  axios
    .post('/purchase/update', data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch({
        type: ADD_PURCHASE,
        payload: response.data,
      });
      dispatch(getPurchaseList());
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
      return Promise.reject();
    });
};

export const deletePurchase = (data) => (dispatch) => {
  axios
    .post('/purchase/delete', data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch({
        type: DELETE_PURCHASE,
        payload: response.data,
      });
      dispatch(getPurchaseList());
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
      return Promise.reject();
    });
};

export const multiplePurchaseDelete = (data) => (dispatch) => {
  axios
    .post('/purchase/deleteMulti', data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch({
        type: MULTIPLE_PURCHASE_DELETE,
        payload: response.data,
      });
      dispatch(getPurchaseList());
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
    });
};

export const approveEmployeeStatus = (data) => (dispatch) => {
  axios
    .post('/purchase/approveEmployeeStatus', data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch(getPurchaseList());
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
      return Promise.reject();
    });
};

export const rejectEmployeeStatus = (data) => (dispatch) => {
  axios
    .post('/purchase/rejectEmployeeStatus', data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch(getPurchaseList());
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
      return Promise.reject();
    });
};
