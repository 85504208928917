import React, { Fragment, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// Destructuring props
const SecondStep = ({
  handleNext,
  handleBack,
  handleChange,
  values: { db_name, db_username, db_pass, db_host, db_port },
  formErrors,
}) => {
  // Check if all values are not empty or if there are some error
  const isValid =
    db_name.length > 0 &&
    !formErrors.db_name &&
    db_username.length > 0 &&
    !formErrors.db_username &&
    // db_pass.length > 0 &&
    // !formErrors.db_pass &&
    db_host.length > 0 &&
    !formErrors.db_host &&
    db_port.length > 0 &&
    !formErrors.db_port;

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (
    <Fragment>
      <Grid container spacing={2} noValidate>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            variant="outlined"
            label="Database Name"
            name="db_name"
            margin="normal"
            value={db_name || ''}
            onChange={handleChange}
            error={!!formErrors.db_name}
            helperText={formErrors.db_name}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            variant="outlined"
            label="Database Username"
            name="db_username"
            margin="normal"
            value={db_username || ''}
            onChange={handleChange}
            error={!!formErrors.db_username}
            helperText={formErrors.db_username}
            required
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            variant="outlined"
            label="Database Password"
            name="db_pass"
            type={showPassword ? 'text' : 'password'}
            margin="normal"
            value={db_pass || ''}
            onChange={handleChange}
            error={!!formErrors.db_pass}
            helperText={formErrors.db_pass}
          // required
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            variant="outlined"
            label="Host"
            name="db_host"
            margin="normal"
            value={db_host || ''}
            onChange={handleChange}
            error={!!formErrors.db_host}
            helperText={formErrors.db_host}
            required
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            variant="outlined"
            label="Port"
            name="db_port"
            margin="normal"
            value={db_port || ''}
            onChange={handleChange}
            error={!!formErrors.db_port}
            helperText={formErrors.db_port}
            required
          />
        </Grid>
      </Grid>
      <div style={{ display: 'flex', marginTop: 50, justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          color="default"
          onClick={handleBack}
          style={{ marginRight: 10 }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          disabled={!isValid}
          color="primary"
          onClick={isValid ? handleNext : null}
        >
          Next
        </Button>
      </div>
    </Fragment>
  );
};

export default SecondStep;
