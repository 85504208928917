import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import StepForm from './components/StepForm';
import Link from '@material-ui/core/Link';
import SvgIcon from '@material-ui/core/SvgIcon';
import { styled } from '@mui/material';
import { Provider, useDispatch } from 'react-redux';
import { Store } from 'app/redux/Store';
import AppLogin from '../app/App';

const WizardTitle = styled('div')(({ theme }) => ({
  // background: '#01c681',
  background: '#103C64',
  height: '55px',
  fontSize: '20px',
  color: '#ffff',
  textAlign: 'center',
  padding: '10px 0px 10px 0px',
  fontFamily: 'poppins',
}));

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    paddingRight: 10,
    paddingLeft: 10,
  },
  svg: {
    verticalAlign: 'middle',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    padding: theme.spacing(0),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
    },
  },
}));

const App = () => {
  const classes = useStyles();

  return (
    <div>
      {localStorage.getItem('install_status') == 1 ? (
        <AppLogin />
      ) : (
        <Provider store={Store}>
          <div className="App">
            <WizardTitle>Annual Maintenance Contract Management System Wizard</WizardTitle>
            <main className={classes.layout}>
              <Paper className={classes.paper}>
                <StepForm />
              </Paper>
            </main>
          </div>
        </Provider>

      )}
    </div>

  );
};

export default App;
