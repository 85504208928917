import axios from 'axios.js';
import { showSnackbar } from './UiActions';

export const GET_EXPENSE_LIST = 'GET_EXPENSE_LIST';
export const ADD_EXPENSE = 'ADD_EXPENSE';
export const UPDATE_EXPENSE = 'UPDATE_EXPENSE';
export const GET_EXPENSE_REPORT = 'GET_EXPENSE_REPORT';
export const DELETE_EXPENSE = 'DELETE_EXPENSE';

export const getExpenseList = () => (dispatch) => {
  axios.get('/expense/list').then((res) => {
    dispatch({
      type: GET_EXPENSE_LIST,
      payload: res.data.data,
    });
  });
};

export const addExpense = (data) => (dispatch) => {
  axios.post('/expense/store', data).then((response) => {
    dispatch(showSnackbar(response.data.message, 'success'));
    dispatch({
      type: ADD_EXPENSE,
      payload: response.data,
    });
    dispatch(getExpenseList());
  })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
    });
};

export const updateExpense = (data) => (dispatch) => {
  axios.post('/expense/update', data).then((response) => {
    dispatch(showSnackbar(response.data.message, 'success'));
    dispatch({
      type: UPDATE_EXPENSE,
      payload: response.data,
    });
    dispatch(getExpenseList());
  })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
    });
};

export const getExpenseReport = (data) => (dispatch) => {
  axios.post('/expense/monthly/report', data).then((response) => {
    // dispatch(showSnackbar(response.data.message, 'success'));
    dispatch({
      type: GET_EXPENSE_REPORT,
      payload: response.data.data,
    });
  })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
    });
};

export const deleteExpense = (data) => (dispatch) => {
  axios.post('/expense/delete', data).then((response) => {
    dispatch(showSnackbar(response.data.message, 'success'));
    dispatch({
      type: DELETE_EXPENSE,
      payload: response.data.data,
    });
    dispatch(getExpenseList());
  })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
    });
};
