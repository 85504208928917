import { Provider } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { MatxTheme } from './components';
import { Snackbar } from './components';
import { AuthProvider } from './contexts/JWTAuthContext';
import { TitleProvider } from './contexts/titleContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { Store, persistor } from './redux/Store';
import routes from './routes';
import ErrorBoundary from './ErrorBoundary';
import { SnackbarProvider } from 'notistack';
import { PersistGate } from 'redux-persist/integration/react'

const App = () => {
  const content = useRoutes(routes);

  return (
    <Provider store={Store}>
      <PersistGate loading={null} persistor={persistor}>
        <ErrorBoundary>
          <SettingsProvider>
            <SnackbarProvider>
              <MatxTheme>
                <Snackbar />
                <AuthProvider>
                  <TitleProvider>
                    {content}
                  </TitleProvider>
                </AuthProvider>
              </MatxTheme>
            </SnackbarProvider>
          </SettingsProvider>
        </ErrorBoundary>
      </PersistGate>
    </Provider >
  );
};

export default App;
