import { ADD_COMPLAINT, ADD_COMPLAINT_TYPE, DELETE_COMPLAINT, DELETE_COMPLAINT_TYPE, GET_COMPLAINT_LIST, MULIPLE_DELETE_COMPLAINT, UPDATE_COMPLAINT } from "../actions/ComplaintActions";


const initialState = {
  complaintList: [],
};

const ComplaintReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_COMPLAINT_LIST: {
      return { ...state, complaintList: action.payload };
    }
    case ADD_COMPLAINT: {
      return { ...state, };
    }
    case UPDATE_COMPLAINT: {
      return { ...state, };
    }
    case DELETE_COMPLAINT: {
      return { ...state, };
    }
    case MULIPLE_DELETE_COMPLAINT: {
      return { ...state, };
    }
    case ADD_COMPLAINT_TYPE: {
      return { ...state, };
    }
    case DELETE_COMPLAINT_TYPE: {
      return { ...state, };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default ComplaintReducer;
