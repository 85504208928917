import { CssBaseline } from '@mui/material';
import { StyledEngineProvider } from '@mui/styled-engine';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import '../src/Fonts/Poppins-Regular.ttf';
import ApiTest from './ApiTest'

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <HashRouter>
      <CssBaseline />
      <I18nextProvider i18n={i18n}>
        <>
          {localStorage.getItem('install_status') == 1 ? (
            <div dir={i18n.language == 'ar' ? 'rtl' : 'ltr'} className='appdirection'>
              <App />
            </div>
          ) : (
            <ApiTest />
          )}
        </>
      </I18nextProvider>
    </HashRouter>
  </StyledEngineProvider >,
  document.getElementById('root')
);

// for IE-11 support un-comment cssVars() and it's import in this file
// and in MatxTheme file

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
