import axios from 'axios.js';
import { showSnackbar } from './UiActions';

export const GET_SETTING_LIST = 'GET_SETTING_LIST';
export const SETTING_UPDATE = 'SETTING_UPDATE';

export const getSettingList = () => (dispatch) => {
  axios.get('/setting/list').then((res) => {
    dispatch({
      type: GET_SETTING_LIST,
      payload: res.data.data,
    });
    window.localStorage.setItem('languageSet', res.data.data.system_language);
    window.localStorage.setItem('SidebarLogo', res.data.data.sidebar_logo);
    window.localStorage.setItem('countryCode', res.data.data.country);
    window.localStorage.setItem('Currency', res.data.data.currency_code);
    window.localStorage.setItem('dateFormat', res.data.data.date_formate);
    window.localStorage.setItem('footerText', res.data.data.footer_text);
    window.localStorage.setItem('startYear', res.data.data.starting_year);
    window.localStorage.setItem('loginlogo', res.data.data.logo);
  });
};

export const settingUpdate = (data) => (dispatch) => {
  axios
    .post('/setting/update', data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch({
        type: SETTING_UPDATE,
        payload: response.data,
      });
      dispatch(getSettingList());
      window.location.reload();
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
      return Promise.reject();
    });
};
