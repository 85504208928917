import axios from 'axios.js';
import { showSnackbar } from './UiActions';

export const GET_EMAIL_TEMPLATE = 'GET_EMAIL_TEMPLATE';
export const UPDATE_TEMPLATE = 'UPDATE_TEMPLATE';

export const getEmailTemplate = () => (dispatch) => {
  axios.get('/template/list').then((res) => {
    dispatch({
      type: GET_EMAIL_TEMPLATE,
      payload: res.data.data,
    });
  })
};

export const updateTemplate = (data) => (dispatch) => {
  axios.post('/template/update', data).then((res) => {
    dispatch(showSnackbar(res.data.message, 'success'));
    dispatch({
      type: UPDATE_TEMPLATE,
      payload: res.data.data,
    });
    dispatch(getEmailTemplate());
  })
};