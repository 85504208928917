import {
  GET_AMC_LIST,
  ADD_AMC,
  UPDATE_AMC,
  DELETE_AMC,
  MULTIPLE_AMC_DELETE,
} from '../actions/AmcActions';

const initialState = {
  amcList: [],
  Data: '',
};

const AmcReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_AMC_LIST: {
      return { ...state, amcList: action.payload };
    }
    case ADD_AMC: {
      return { ...state, Data: action.payload };
    }
    case UPDATE_AMC: {
      return { ...state, Data: action.payload };
    }
    case DELETE_AMC: {
      return { ...state };
    }
    case MULTIPLE_AMC_DELETE: {
      return { ...state };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default AmcReducer;
