import { GET_MODULE_LIST, GET_ROLE_LIST, UPDATE_ACCESSRIGHT } from '../actions/AccessRightsActions';

const initialState = {
  roleList: [],
  moduleList: [],
};

const AccessRightsReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_ROLE_LIST: {
      return { ...state, roleList: action.payload };
    }
    case GET_MODULE_LIST: {
      return { ...state, moduleList: action.payload };
    }
    case UPDATE_ACCESSRIGHT: {
      return { ...state };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default AccessRightsReducer;
