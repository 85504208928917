import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import languageEN from './Locate/en/translate.json';
import languageHI from './Locate/hi/translate.json';
import languageCA from './Locate/ca/translate.json';
import languageCS from './Locate/cs/translate.json';
import languageDA from './Locate/da/translate.json';
import languageDE from './Locate/de/translate.json';
import languageEL from './Locate/el/translate.json';
import languageES from './Locate/es/translate.json';
import languageET from './Locate/et/translate.json';
import languageFA from './Locate/fa/translate.json';
import languageFI from './Locate/fi/translate.json';
import languageFR from './Locate/fr/translate.json';
import languageHR from './Locate/hr/translate.json';
import languageHU from './Locate/hu/translate.json';
import languageID from './Locate/id/translate.json';
import languageJA from './Locate/ja/translate.json';
import languageIT from './Locate/it/translate.json';
import languageLT from './Locate/lt/translate.json';
import languageNL from './Locate/nl/translate.json';
import languageNO from './Locate/no/translate.json';
import languagePL from './Locate/pl/translate.json';
import languagePT from './Locate/pt/translate.json';
import languagePTBR from './Locate/pt_br/translate.json';
import languageRO from './Locate/ro/translate.json';
import languageRU from './Locate/ru/translate.json';
import languageSV from './Locate/sv/translate.json';
import languageTR from './Locate/tr/translate.json';
import languageVI from './Locate/vi/translate.json';
import languageZH from './Locate/zh/translate.json';
import languageZHTW from './Locate/zh_tw/translate.json';
import languageAR from './Locate/ar/translate.json';

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      ar: languageAR,
      zh_tw: languageZHTW,
      zh: languageZH,
      vi: languageVI,
      tr: languageTR,
      sv: languageSV,
      ru: languageRU,
      ro: languageRO,
      pt_br: languagePTBR,
      pt: languagePT,
      pl: languagePL,
      no: languageNO,
      nl: languageNL,
      lt: languageLT,
      it: languageIT,
      ja: languageJA,
      id: languageID,
      en: languageEN,
      hi: languageHI,
      ca: languageCA,
      cs: languageCS,
      da: languageDA,
      de: languageDE,
      el: languageEL,
      es: languageES,
      et: languageET,
      fa: languageFA,
      fi: languageFI,
      fr: languageFR,
      hr: languageHR,
      hu: languageHU,
    },
    /* default language when load the website in browser */
    lng: window.localStorage.getItem('languageSet'),
    reloadOnLanguageChange: false,
    /* When react i18next not finding any language to as default in borwser */
    fallbackLng: 'en',
    /* debugger For Development environment */
    debug: true,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {
      wait: true,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default',
    },
  });

export default i18n;
