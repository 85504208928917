import axios from 'axios.js';
import { getCategoryList } from './CategoryActions';
import { showSnackbar } from './UiActions';

export const GET_COMPLAINT_LIST = 'GET_COMPLAINT_LIST';
export const ADD_COMPLAINT = 'ADD_COMPLAINT';
export const UPDATE_COMPLAINT = 'UPDATE_COMPLAINT';
export const DELETE_COMPLAINT = 'DELETE_COMPLAINT';
export const MULIPLE_DELETE_COMPLAINT = 'MULIPLE_DELETE_COMPLAINT';
export const ADD_COMPLAINT_TYPE = 'ADD_COMPLAINT_TYPE';
export const DELETE_COMPLAINT_TYPE = 'DELETE_COMPLAINT_TYPE';

export const getcomplaintList = () => (dispatch) => {
  console.log("Entered");
  axios.get('/complaint/list').then((res) => {
    dispatch({
      type: GET_COMPLAINT_LIST,
      payload: res.data.data,
    });
  });
};

// export const addComplaint = (data) => (dispatch) => {
//   axios
//     .post('/complaint/store', data)
//     .then((response) => {
//       dispatch(showSnackbar(response.data.message, 'success'));
//       dispatch({
//         type: ADD_COMPLAINT,
//         payload: response.data.data,
//       });
//       dispatch(getcomplaintList());
//     })
//     .catch((error) => {
//       dispatch(showSnackbar(error.message, 'error'));
//       return Promise.reject();
//     });
// };

export const addComplaint = (data) => async (dispatch) => {
  try {
    const response = await axios.post("/complaint/store", data);
    dispatch(showSnackbar(response.data.message, "success"));
    dispatch({
      type: ADD_COMPLAINT,
      payload: response.data.data,
    });
    if (response.status === 200) {
      dispatch(getcomplaintList());
    }

    return response;
  } catch (error) {
    dispatch(
      showSnackbar(error.response?.data?.message || error.message, "error")
    );
    return error.response?.data?.message || error.message;
  }
};

export const updateComplaint = (data) => (dispatch) => {
  axios
    .post('/complaint/update', data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch({
        type: UPDATE_COMPLAINT,
        payload: response.data.data,
      });
      dispatch(getcomplaintList());
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
      return Promise.reject();
    });
};

export const deleteComplaint = (data) => (dispatch) => {
  axios
    .post('/complaint/delete', data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch({
        type: DELETE_COMPLAINT,
        payload: response.data.data,
      });
      dispatch(getcomplaintList());
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
      return Promise.reject();
    });
};

export const multipledeleteComplaint = (data) => (dispatch) => {
  axios
    .post('/complaint/deleteMulti', data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch({
        type: MULIPLE_DELETE_COMPLAINT,
        payload: response.data.data,
      });
      dispatch(getcomplaintList());
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
      return Promise.reject();
    });
};

export const addComplaintType = (data) => (dispatch) => {
  axios.post('/category/store', data).then((response) => {
    dispatch(showSnackbar(response.data.message, 'success'));
    dispatch({
      type: ADD_COMPLAINT_TYPE,
      payload: response.data,
    });
    dispatch(getCategoryList());
  })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
    });
};

export const reomveComplaintType = (data) => (dispatch) => {
  axios.post('/category/delete', data).then((response) => {
    dispatch(showSnackbar(response.data.message, 'success'));
    dispatch({
      type: DELETE_COMPLAINT_TYPE,
      payload: response.data,
    });
    dispatch(getCategoryList());
  })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
    });
};