import {
  Drawer,
  Icon,
  IconButton,
  ThemeProvider,
  useMediaQuery,
} from '@mui/material';
import { Box, styled, useTheme } from '@mui/system';
import useSettings from 'app/hooks/useSettings';
import { topBarHeight } from 'app/utils/constant';
import React, { Fragment } from 'react';
import { themeShadows } from './MatxTheme/themeColors';
import i18n from 'i18next'

const Notification = styled('div')(() => ({
  padding: '16px',
  marginBottom: '16px',
  display: 'flex',
  alignItems: 'center',
  height: topBarHeight,
  boxShadow: themeShadows[6],
  '& h5': {
    marginLeft: '8px',
    marginTop: 0,
    marginBottom: 0,
    fontWeight: '500',
  },
}));

const DrawerLable = styled('div')(() => ({
  fontFamily: 'poppins',
  color: '#333333',
  fontWeight: '500',
  fontSize: '15px',
  marginLeft: '5px'
}));

const DrawerTitle = styled('div')(() => ({
  width: '100%'
}));

const DrawerForm = ({ children, handleDrawerToggle, panelOpen, title, icon, drawerWidth }) => {
  const { settings } = useSettings();
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Fragment>
      <ThemeProvider theme={settings.themes[settings.activeTheme]}>
        <Drawer
          width={'100px'}
          // container={container}
          variant="temporary"
          anchor={'right'}
          open={panelOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={
            largeScreen
              ? {
                sx: {
                  width: drawerWidth,
                },
              }
              : {
                sx: {
                  width: '100%',
                },
              }
          }
        >
          <Box sx={{ width: '100%' }}>
            <Notification>
              <Icon color="primary">{icon}</Icon>
              <DrawerTitle>
                <DrawerLable>{title}</DrawerLable>
              </DrawerTitle>
              <IconButton onClick={handleDrawerToggle}>
                <Icon color="primary">close</Icon>
              </IconButton>
            </Notification>
            {children}
            {/* 
            {notifications?.map((notification) => (
              <NotificationCard key={notification.id}>
                <DeleteButton
                  size="small"
                  className="deleteButton"
                  onClick={() => deleteNotification(notification.id)}
                >
                  <Icon className="icon">clear</Icon>
                </DeleteButton>
                <Link
                  to={`/${notification.path}`}
                  onClick={handleDrawerToggle}
                  style={{ textDecoration: 'none' }}
                >
                  <Card sx={{ mx: 2, mb: 3 }} elevation={3}>
                    <CardLeftContent>
                      <Box display="flex">
                        <Icon className="icon" color={notification.icon.color}>
                          {notification.icon.name}
                        </Icon>
                        <Heading>{notification.heading}</Heading>
                      </Box>
                      <Small className="messageTime">
                        {getTimeDifference(new Date(notification.timestamp))}
                        ago
                      </Small>
                    </CardLeftContent>
                    <Box sx={{ px: 2, pt: 1, pb: 2 }}>
                      <Paragraph sx={{ m: 0 }}>{notification.title}</Paragraph>
                      <Small sx={{ color: secondary }}>{notification.subtitle}</Small>
                    </Box>
                  </Card>
                </Link>
              </NotificationCard>
            ))}
            {!!notifications?.length && (
              <Box sx={{ color: secondary }}>
                <Button onClick={clearNotifications}>Clear Notifications</Button>
              </Box>
            )} */}
          </Box>
        </Drawer>
      </ThemeProvider>
    </Fragment>
  );
};

export default DrawerForm;
