import axios from 'axios.js';
import { showSnackbar } from './UiActions';

export const GET_SALES_REPORT = 'GET_SALES_REPORT';
export const GET_SERVICE_REPORT = 'GET_SERVICE_REPORT';
export const GET_COMPLAINT_REPORT = 'GET_COMPLAINT_REPORT';

export const getSalesReport = (data) => (dispatch) => {
  axios.post('/report/saleReport', data).then((res) => {
    // dispatch(showSnackbar(res.data.message, 'success'));
    dispatch({
      type: GET_SALES_REPORT,
      payload: res.data.data,
    });
  })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
    });
};

export const getServiceReport = (data) => (dispatch) => {
  axios.post('/report/serviceReport', data).then((res) => {
    // dispatch(showSnackbar(res.data.message, 'success'));
    dispatch({
      type: GET_SERVICE_REPORT,
      payload: res.data.data,
    });
  })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
    });
};

export const getComplaintReport = (data) => (dispatch) => {
  axios.post('/report/complaintReport', data).then((res) => {
    // dispatch(showSnackbar(res.data.message, 'success'));
    dispatch({
      type: GET_COMPLAINT_REPORT,
      payload: res.data.data,
    });
  })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
    });
};
