import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

// Destructuring props
const FirstStep = ({
  handleNext,
  handleChange,
  values: { domain_name, purchase_key, purchase_email, gender },
  formErrors,
}) => {
  // Check if all values are not empty or if there are some error
  const isValid =
    domain_name.length > 0 &&
    !formErrors.domain_name &&
    purchase_key.length > 0 &&
    !formErrors.purchase_key &&
    purchase_email.length > 0 &&
    !formErrors.purchase_email;

  return (
    <Fragment>
      <Grid container spacing={2} noValidate>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            variant="outlined"
            label="Domain Name"
            name="domain_name"
            margin="normal"
            value={domain_name || ''}
            onChange={handleChange}
            error={!!formErrors.domain_name}
            helperText={formErrors.domain_name}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            variant="outlined"
            label="Purchase Key"
            name="purchase_key"
            margin="normal"
            value={purchase_key || ''}
            onChange={handleChange}
            error={!!formErrors.purchase_key}
            helperText={formErrors.purchase_key}
            required
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            variant="outlined"
            label="email"
            name="purchase_email"
            type="purchase_email"
            value={purchase_email || ''}
            onChange={handleChange}
            margin="normal"
            error={!!formErrors.purchase_email}
            helperText={formErrors.purchase_email}
            required
          />
        </Grid>
      </Grid>
      <div style={{ display: 'flex', marginTop: 50, justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          disabled={!isValid}
          color="primary"
          onClick={isValid ? handleNext : null}
        >
          Next
        </Button>
      </div>
    </Fragment>
  );
};

export default FirstStep;
