import axios from 'axios.js';
import { getCategoryList } from './CategoryActions';
import { showSnackbar } from './UiActions';

export const GET_PRODUCT_DROPDOWN = 'GET_PRODUCT_DROPDOWN';
export const GET_PRODUCT_LIST = 'GET_PRODUCT_LIST';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const UPDATE_PRODUCT_DETAILS = 'UPDATE_PRODUCT_DETAILS';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const MAKE_ARCHIVE_PRODUCT = 'MAKE_ARCHIVE_PRODUCT';
export const GET_ARCHIVE_LIST = 'GET_ARCHIVE_LIST';
export const ADD_PRODUCT_BRAND = 'ADD_PRODUCT_BRAND';
export const DELETE_PRODUCT_BRAND = 'DELETE_PRODUCT_BRAND';
export const MULTIPLE_PRODUCT_DELETE = 'MULTIPLE_PRODUCT_DELETE';

export const getproductList = () => (dispatch) => {
  axios.get('/product/list').then((res) => {
    dispatch({
      type: GET_PRODUCT_LIST,
      payload: res.data.data,
    });
  });
};

export const getArchiveList = () => (dispatch) => {
  axios.get('/product/archiveList').then((res) => {
    dispatch({
      type: GET_ARCHIVE_LIST,
      payload: res.data.data,
    });
  });
};

export const addProducts = (data) => (dispatch) => {
  axios
    .post('/product/store', data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch({
        type: ADD_PRODUCT,
        payload: response.data,
      });
      dispatch(getproductList());
      dispatch(getProductDropdown());
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
    });
};

export const addProductBrand = (data) => (dispatch) => {
  axios
    .post('/category/store', data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch({
        type: ADD_PRODUCT_BRAND,
        payload: response.data,
      });
      dispatch(getCategoryList());
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
    });
};

export const reomveProductBrand = (data) => (dispatch) => {
  axios
    .post('/category/delete', data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch({
        type: DELETE_PRODUCT_BRAND,
        payload: response.data,
      });
      dispatch(getCategoryList());
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
    });
};

export const updateProductDetails = (data) => (dispatch) => {
  axios
    .post('/product/update', data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch({
        type: UPDATE_PRODUCT_DETAILS,
        payload: response.data,
      });
      window.location.reload()
      dispatch(getproductList());
      dispatch(getProductDropdown());
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
    });
};

export const deleteProduct = (data) => (dispatch) => {
  axios
    .post('/product/delete', data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch({
        type: DELETE_PRODUCT,
        payload: response.data,
      });
      dispatch(getproductList());
      dispatch(getArchiveList());
      dispatch(getProductDropdown());
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
    });
};

export const multipleProductDelete = (data) => (dispatch) => {
  axios
    .post('/product/deleteMulti', data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch({
        type: MULTIPLE_PRODUCT_DELETE,
        payload: response.data,
      });
      dispatch(getproductList());
      dispatch(getProductDropdown());
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
    });
};

export const archiveProduct = (data) => (dispatch) => {
  axios
    .post('/product/makeArchive', data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch({
        type: MAKE_ARCHIVE_PRODUCT,
        payload: response.data,
      });
      dispatch(getproductList());
      dispatch(getArchiveList());
      dispatch(getProductDropdown());
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
    });
};

export const getProductDropdown = () => (dispatch) => {
  axios.get('/product/produtcDropdown').then((res) => {
    dispatch({
      type: GET_PRODUCT_DROPDOWN,
      payload: res.data,
    });
  });
};
