import {
  GET_TAX_LIST,
  ADD_TAX,
  UPDATE_TAX,
  DELETE_TAX,
  MULTIPLE_TAX_DELETE,
} from '../actions/TaxActions';

const initialState = {
  taxList: [],
  Data: '',
};

const TaxReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_TAX_LIST: {
      return { ...state, taxList: action.payload };
    }
    case ADD_TAX: {
      return { ...state, Data: action.payload };
    }
    case UPDATE_TAX: {
      return { ...state, Data: action.payload };
    }
    case DELETE_TAX: {
      return { ...state };
    }
    case MULTIPLE_TAX_DELETE: {
      return { ...state };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default TaxReducer;
