import axios from 'axios.js';
import { showSnackbar } from './UiActions';

export const GET_TAX_LIST = 'GET_TAX_LIST';
export const ADD_TAX = 'ADD_TAX';
export const UPDATE_TAX = 'UPDATE_TAX';
export const DELETE_TAX = 'DELETE_TAX';
export const MULTIPLE_TAX_DELETE = 'MULTIPLE_TAX_DELETE';

export const getTaxList = () => (dispatch) => {
  axios.get('/tax/list').then((res) => {
    dispatch({
      type: GET_TAX_LIST,
      payload: res.data,
    });
  });
};

export const addTax = (data) => (dispatch) => {
  axios
    .post('/tax/store', data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch({
        type: ADD_TAX,
        payload: response.data,
      });
      dispatch(getTaxList());
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
      return Promise.reject();
    });
};

export const updateTax = (data) => (dispatch) => {
  axios
    .post('/tax/update', data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch({
        type: ADD_TAX,
        payload: response.data,
      });
      dispatch(getTaxList());
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
      return Promise.reject();
    });
};

export const deleteTax = (data) => (dispatch) => {
  axios
    .post('/tax/delete', data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch({
        type: DELETE_TAX,
        payload: response.data,
      });
      dispatch(getTaxList());
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
      return Promise.reject();
    });
};

export const multipleTaxDelete = (data) => (dispatch) => {
  axios
    .post('/tax/deleteMulti', data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch({
        type: MULTIPLE_TAX_DELETE,
        payload: response.data,
      });
      dispatch(getTaxList());
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
    });
};
