import axios from 'axios.js';
import { showSnackbar } from './UiActions';

export const GET_QUOTATIONS_LIST = 'GET_QUOTATIONS_LIST';
export const ADD_QUOTATION = 'ADD_QUOTATION';
export const EDIT_QUOTATION = 'EDIT_QUOTATION';
export const DELETE_QUOTATION = 'DELETE_QUOTATION';
export const MULTIPLE_DELETE_QUOTATION = 'MULTIPLE_DELETE_QUOTATION';
export const LOADING_START_QUOTATION = 'LOADING_START_QUOTATION';
export const LOADING_END_QUOTATION = 'LOADING_END_QUOTATION';

export const getquotationsList = () => (dispatch) => {
  axios.get('/quotation/list').then((res) => {
    dispatch({
      type: GET_QUOTATIONS_LIST,
      payload: res.data.data,
    });
  });
};

export const addQuotation = (data) => (dispatch) => {
  axios
    .post('/quotation/store', data)
    .then((response) => {
      dispatch({
        type: ADD_QUOTATION,
        payload: response.data,
      });
      dispatch(getquotationsList());
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch(loadingEnd());
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
    });
};

export const editQuotation = (data) => (dispatch) => {
  axios
    .post('/quotation/update', data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch({
        type: EDIT_QUOTATION,
        payload: response.data,
      });
      dispatch(getquotationsList());
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
    });
};

export const deleteQuotation = (data) => (dispatch) => {
  axios
    .post('/quotation/delete', data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch({
        type: DELETE_QUOTATION,
        payload: response.data,
      });
      dispatch(getquotationsList());
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
    });
};

export const multipledeleteQuotation = (data) => (dispatch) => {
  axios
    .post('/quotation/deleteMulti', data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch({
        type: MULTIPLE_DELETE_QUOTATION,
        payload: response.data,
      });
      dispatch(getquotationsList());
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
    });
};

export const loadingStart = () => (dispatch) => {
  dispatch({
    type: LOADING_START_QUOTATION,
  });
};

export const loadingEnd = () => (dispatch) => {
  dispatch({
    type: LOADING_END_QUOTATION,
  });
};
