import i18n from 'i18n';

const Adminnavigations = [
  { name: 'Dashboard', path: '/dashboard/default', icon: 'dashboard' },
  { name: 'Users', path: '/users/list', icon: 'group' },
  {
    name: i18n.t('Products'),
    icon: 'shopping_cart',
    children: [
      { name: 'Product', path: '/product/list', icon: 'shopping_cart' },
      { name: 'Purchase', path: '/purchase/list', icon: 'inventory' },
      { name: 'Stock', path: '/stock/list', icon: 'store' },
    ],
  },
  { name: 'AMC', path: '/amc/list', icon: 'miscellaneous_services' },
  { name: 'Services', path: '/service/list', icon: 'support_agent' },
  { name: 'Complaints', path: '/complaint/list', icon: 'reviewsicon' },
  { name: 'Store Service', path: '/storeservice/list', icon: 'store' },
  { name: 'Quotations', path: '/quotation/list', icon: 'description' },
  { name: 'Sell', path: '/sales/list', icon: 'sell' },
  {
    name: i18n.t('Accounts'),
    icon: 'paid',
    children: [
      { name: 'Income', path: '/income/list', icon: 'money' },
      { name: 'Expense', path: '/expense/list', icon: 'money' },
      { name: 'Tax', path: '/tax/list', icon: 'paid' },
    ],
  },
  { name: 'Task', path: '/task/list', icon: 'assignmentturned' },
  { name: 'Reports', path: '/report/list', icon: 'assessment' },
  {
    name: i18n.t('General Settings'),
    icon: 'settings',
    children: [
      { name: 'Email Template', path: '/email-template/list', icon: 'email' },
      { name: 'Settings', path: '/setting', icon: 'settings' },
      { name: 'Access Rights', path: '/accessrights', icon: 'key' },
    ],
  },
];

const Customernavigations = [
  { name: 'Dashboard', path: '/dashboard/default', icon: 'dashboard' },
  { name: 'Users', path: '/users/list', icon: 'group' },
  { name: 'AMC', path: '/amc/list', icon: 'miscellaneous_services' },
  { name: 'Services', path: '/service/list', icon: 'support_agent' },
  { name: 'Complaints', path: '/complaint/list', icon: 'reviewsicon' },
  {
    name: i18n.t('Accounts'),
    icon: 'paid',
    children: [
      { name: 'Income', path: '/income/list', icon: 'money' },
      { name: 'Expense', path: '/expense/list', icon: 'money' },
      { name: 'Tax', path: '/tax/list', icon: 'paid' },
    ],
  },
  { name: 'Reports', path: '/report/list', icon: 'assessment' },
];

export const navigations =
  window.localStorage.getItem('UserRole') === 'admin' ? Adminnavigations : Customernavigations;

export default navigations;
