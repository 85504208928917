import { GET_SETTING_LIST, SETTING_UPDATE } from "../actions/SettingsActions";

const initialState = {
  settingList: '',
};

const SettingsReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_SETTING_LIST: {
      return { ...state, settingList: action.payload };
    }
    case SETTING_UPDATE: {
      return { ...state };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default SettingsReducer;
