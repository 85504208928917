import { GET_COMPLAINT_REPORT, GET_SALES_REPORT, GET_SERVICE_REPORT } from "../actions/ReportActions";

const initialState = {
  salesReport: [],
  serviceReport: [],
  complaintReport: [],
};

const ReportReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_SALES_REPORT: {
      return { ...state, salesReport: action.payload };
    }
    case GET_SERVICE_REPORT: {
      return { ...state, serviceReport: action.payload };
    }
    case GET_COMPLAINT_REPORT: {
      return { ...state, complaintReport: action.payload };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default ReportReducer;
