import { ADD_TASK, ADD_TASK_TYPE, DELETE_TASK, DELETE_TASK_TYPE, GET_TASK_LIST, MULTIPLE_DELETE_TASK, UPDATE_TASK } from "../actions/TaskActions";

const initialState = {
  taskList: [],
};

const TaskReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_TASK_LIST: {
      return { ...state, taskList: action.payload };
    }
    case ADD_TASK: {
      return { ...state, };
    }
    case DELETE_TASK: {
      return { ...state, };
    }
    case UPDATE_TASK: {
      return { ...state, };
    }
    case MULTIPLE_DELETE_TASK: {
      return { ...state, };
    }
    case ADD_TASK_TYPE: {
      return { ...state, };
    }
    case DELETE_TASK_TYPE: {
      return { ...state, };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default TaskReducer;
