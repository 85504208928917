import {
  GET_PURCHASE_LIST,
  ADD_PURCHASE,
  UPDATE_PURCHASE,
  DELETE_PURCHASE,
  MULTIPLE_PURCHASE_DELETE,
} from '../actions/PurchaseActions';

const initialState = {
  purchaseList: [],
  Data: '',
};

const PurchaseReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_PURCHASE_LIST: {
      return { ...state, purchaseList: action.payload };
    }
    case ADD_PURCHASE: {
      return { ...state, Data: action.payload };
    }
    case UPDATE_PURCHASE: {
      return { ...state, Data: action.payload };
    }
    case DELETE_PURCHASE: {
      return { ...state };
    }
    case MULTIPLE_PURCHASE_DELETE: {
      return { ...state };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default PurchaseReducer;
