import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const StoreService = Loadable(lazy(() => import('./index.js')));

const storeserviceRoutes = [
  { path: '/storeservice/list', element: <StoreService />, auth: authRoles.admin },
];

export default storeserviceRoutes;
