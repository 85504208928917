import { ADD_INCOME, DELETE_INCOME, GET_INCOME_LIST, GET_INCOME_REPORT, UPDATE_INCOME } from "../actions/IncomeActions";

const initialState = {
  incomeList: [],
  incomeReport: [],
};

const IncomeReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_INCOME_LIST: {
      return { ...state, incomeList: action.payload };
    }
    case ADD_INCOME: {
      return { ...state, };
    }
    case UPDATE_INCOME: {
      return { ...state, };
    }
    case DELETE_INCOME: {
      return { ...state, };
    }
    case GET_INCOME_REPORT: {
      return { ...state, incomeReport: action.payload };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default IncomeReducer;
