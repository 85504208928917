import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const Accessrights = Loadable(lazy(() => import('./accessrights')));

const accessrightsRoutes = [
  { path: '/accessrights', element: <Accessrights />, auth: authRoles.admin },
];

export default accessrightsRoutes;
