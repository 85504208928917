import { TitleContext } from 'app/contexts/titleContext';
import React, { useContext, useEffect, useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, createTheme, FormControl, FormControlLabel, Grid, Icon, InputAdornment, MenuItem, Radio, RadioGroup, Stack, Tab, TextField, ThemeProvider, Tooltip, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { Span } from 'app/components/Typography';
import { useLocation } from 'react-router-dom';
import { getUsersDetails, updateUserDetails } from 'app/redux/actions/UsersActions';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import Call_Vector from '../../assets/images/Call_Vector.png';
import Email_Vector from '../../assets/images/Email_Vector.png';
import Location from '../../assets/images/pin.png';
import Edit_Button from '../../assets/images/Edit_Button.png';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import RemoveCircleSharpIcon from '@mui/icons-material/RemoveCircleSharp';
import { DrawerForm } from 'app/components';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { themeShadows } from 'app/components/MatxTheme/themeColors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FieldArray, Formik } from 'formik';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Tooltip_Vector from '../../assets/images/tooltip_Vector.png'
import i18n from './../../../i18n';

var TodayDate = dayjs();


const Title = styled('span')(() => ({
    fontSize: '1rem',
    fontWeight: '500',
    marginRight: '.5rem',
    textTransform: 'capitalize',
}));

const NoDataContainer = styled('div')(({ theme }) => ({
    textAlign: 'center',
    marginTop: '60px',
    marginBottom: '60px',
    fontSize: '18px',
    fontFamily: 'poppins'
}));

const Generaluserdetails = styled('div')(({ theme }) => ({
    fontFamily: 'poppins',
    fontSize: '14px',
    fontWeight: '400px',
    color: '#AAAAAA'
}));

const Generaluserlabel = styled('div')(({ theme }) => ({
    fontFamily: 'poppins',
    fontSize: '14px',
    fontWeight: '400px',
    color: '#AAAAAA',
    marginTop: '15px'
}));

const Headerusername = styled('div')(({ theme }) => ({
    fontFamily: 'poppins',
    fontSize: '22px',
    fontWeight: '500px',
    color: '#FFFFFF',
    marginTop: '43px',
    marginLeft: '0px',
    '@media only screen and (max-width: 450px)': {
        fontFamily: 'poppins',
        fontSize: '14px',
        fontWeight: '500px',
        color: '#FFFFFF',
        marginTop: '35px',
    },
}));

const Headeruserdetails = styled('div')(({ theme }) => ({
    fontFamily: 'poppins',
    fontSize: '15px',
    fontWeight: '400px',
    color: '#CCDAE6',
    marginTop: '10px',
    marginLeft: '0px',
    wordBreak: 'break-all',
    '@media only screen and (max-width: 450px)': {
        fontFamily: 'poppins',
        fontSize: '12px',
        marginTop: '4px',
        fontWeight: '400px',
        color: '#CCDAE6',
        wordBreak: 'break-all'
    },
}));

const Headeruseraddress = styled('div')(({ theme }) => ({
    fontFamily: 'poppins',
    fontSize: '15px',
    fontWeight: '400px',
    color: '#818386',
    marginLeft: '153px',
    marginTop: '20px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '750px',
    overflow: 'hidden',
    display: 'inline-block',
    marginRight: i18n.language === 'ar' ? "150px" : "",
    '@media only screen and (max-width: 450px)': {
        fontFamily: 'poppins',
        fontSize: '12px',
        fontWeight: '400px',
        color: '#818386',
        position: 'absolute',
        marginLeft: '142px',
        marginTop: '15px',
        whiteSpace: 'break-spaces',
        textOverflow: 'ellipsis',
        width: '220px',
        overflow: 'hidden',
        display: 'inline-block',
    },
}));

const GeneraluserData = styled('div')(({ theme }) => ({
    fontFamily: 'poppins',
    fontSize: '14px',
    fontWeight: '400px',
    color: '#333333'
}));

const ServiceSecondDate = styled('div')(({ theme }) => ({
    fontFamily: 'poppins',
    fontSize: '14px',
    fontWeight: 500,
    color: '#333333',
    lineHeight: '21px',
    letterSpacing: '0em',
    textAlign: 'center',
    marginTop: '17px',
    height: '40px',
    borderRight: '4px solid #36A8EB'
}));

const ComplaintuserData = styled('div')(({ theme }) => ({
    fontFamily: 'poppins',
    fontSize: '15px',
    fontWeight: '400px',
    color: '#333333'
}));

const GeneraluserOtherData = styled(Span)(({ theme }) => ({
    fontFamily: 'poppins',
    fontSize: '14px',
    fontWeight: '400px',
    color: '#333333',
    marginLeft: '3px'
}));

const Formlable = styled('div')(() => ({
    fontFamily: 'poppins',
    color: '#333333',
    fontWeight: '600',
    fontSize: '15px'
}));

const GenderLable = styled('div')(() => ({
    fontFamily: 'poppins',
    color: '#818386',
    fontWeight: '500',
    fontSize: '15px'
}));

const TypographyLable = styled(Typography)(() => ({
    fontFamily: 'poppins',
    color: '#333333',
    fontWeight: '400',
    fontSize: '15px'
}));

const BootstrapButton = styled(Button)({
    // backgroundColor: '#01C681',
    backgroundColor: '#103C64',
    fontFamily: 'poppins',
    fontSize: '14px',
    fontWeight: '400',
    '&:hover': {
        // backgroundColor: '#01C681',
        backgroundColor: '#103C64',
    },
    '&:active': {
        // backgroundColor: '#01C681',
        backgroundColor: '#103C64',
    },
});


const Container = styled('div')(({ theme }) => ({
    marginRight: "30px",
    marginLeft: "30px",
    marginBottom: "30px",
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
    },
}));

const AddSecondaccordion = styled('div')(() => ({
    marginLeft: "15px",
    marginTop: "15px",
    marginRight: "15px",
    borderRadius: "5px",
    boxShadow: themeShadows[6],
    '& h5': {
        marginLeft: '8px',
        marginTop: 0,
        marginBottom: 0,
        fontWeight: '500',
    },
}));

const SubmitButton = styled('div')(() => ({
    margin: "15px",
}));

const FormCard = styled('div')(() => ({
    padding: '16px',
    marginBottom: '0px',
    display: 'flex',
    alignItems: 'center',
}));

const CardTitle = styled('div')(() => ({
    fontWeight: 'bold',
    marginLeft: "10px"
}));

const ComplaintSecondDate = styled('div')(({ theme }) => ({
    fontFamily: 'poppins',
    fontSize: '14px',
    fontWeight: 500,
    color: '#333333',
    lineHeight: '21px',
    letterSpacing: '0em',
    textAlign: 'center',
    marginTop: '17px',
    height: '40px',
    borderRight: '4px solid #FF9054'
}));


const Viewuser = () => {

    const { setTitle, setAction } = useContext(TitleContext);
    const [value, setValue] = React.useState('1');
    const dispatch = useDispatch()
    const location = useLocation();
    const [panelOpen, setPanelOpen] = useState(false);
    const [image, setImage] = useState();
    const [dobvalue, setDOBValue] = React.useState();

    const usersData = useSelector((state) => state.users)
    console.log(usersData);
    
    const { t, i18n } = useTranslation();

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const handleDrawerToggle = (Data, action) => {
        // setUserData(Data)
        // setUserAction(action)
        // setDrawerTitle(Data.role_name)
        setPanelOpen(!panelOpen);
    };

    useEffect(() => {
        dispatch(getUsersDetails({ email: location.state.UserData.email, mobile: location.state.UserData.mobile }))
        setAction("View")
        setTitle(usersData.userDetails.full_name);
    }, [usersData.userDetails.full_name])


    const Empty = { contact_person: "", mobile: "", designation: "" }
    var Permission = JSON.parse(window.localStorage.getItem('Permission'));

    // inital add uesr values
    const initialValues = {
        first_name: location.state.UserData !== undefined ? location.state.UserData.first_name : "",
        last_name: location.state.UserData !== undefined ? location.state.UserData.last_name : "",
        gender: location.state.UserData !== undefined ? location.state.UserData.gender : "",
        birth_date: location.state.UserData !== undefined ? location.state.UserData.birth_date : "",
        address: location.state.UserData !== undefined ? location.state.UserData.address : "",
        city: location.state.UserData !== undefined ? location.state.UserData.city : "",
        state: location.state.UserData !== undefined ? location.state.UserData.state : "",
        pincode: location.state.UserData !== undefined ? location.state.UserData.pincode : "",
        mobile: location.state.UserData !== undefined ? location.state.UserData.mobile : "",
        email: location.state.UserData !== undefined ? location.state.UserData.email : "",
        password: "",
        password_confirmation: "",
        profile: null,
        role: location.state.UserData !== undefined ? location.state.UserData.role_name : "",
        account_number: location.state.UserData !== undefined ? location.state.UserData.account_number : "",
        bank_name: location.state.UserData !== undefined ? location.state.UserData.bank_name : "",
        branch_name: location.state.UserData !== undefined ? location.state.UserData.branch_name : "",
        swift_code: location.state.UserData !== undefined ? location.state.UserData.swift_code : "",
        branch_code: location.state.UserData !== undefined ? location.state.UserData.branch_code : "",
        international_bank_code: location.state.UserData !== undefined ? location.state.UserData.international_bank_code : "",
        national_bank_code: location.state.UserData !== undefined ? location.state.UserData.national_bank_code : "",
        gst: location.state.UserData !== undefined ? location.state.UserData.gst : "",
        company_name: location.state.UserData !== undefined ? location.state.UserData.company_name : "",
        company_contact_person: location.state.UserData !== undefined ? location.state.UserData.company_contact_person : "",
        ifsc_code: location.state.UserData !== undefined ? location.state.UserData.ifsc_code : "",
        tin_no: location.state.UserData !== undefined ? location.state.UserData.tin_no : "",
        cst_no: location.state.UserData !== undefined ? location.state.UserData.cst_no : "",
        pan_no: location.state.UserData !== undefined ? location.state.UserData.pan_no : "",
        phone: location.state.UserData !== undefined ? location.state.UserData.phone : "",
        OtherContact: location.state.UserData !== undefined && location.state.UserData.customer_contacts !== null ? location.state.UserData.customer_contacts : [Empty],
    };

    // form field validation schema

    let validationSchema = ""

    if (location.state.UserData && location.state.UserData.role_name === "supplier") {

        validationSchema = Yup.object().shape({

            first_name: Yup.string().required(i18n.t('First name is required!')).matches(/^[a-zA-Z_ ]*$/, "Should contain only alphabets"),
            last_name: Yup.string().required(i18n.t('Last name is required!')).matches(/^[a-zA-Z_ ]*$/, "Should contain only alphabets"),
            gender: Yup.string().required(i18n.t('Gender is required!')),
            birth_date: Yup.string().required(i18n.t('DOB is required!')),
            address: Yup.string().required(i18n.t('Address is required!')),
            city: Yup.string().required(i18n.t('City is required!')),
            state: Yup.string().required(i18n.t('State is required!')),
            pincode: Yup.string().required(i18n.t('Pincode is required!'))
                .matches(/^[0-9]{0,10}$/, "Pincode should not be more than 10 digits."),
            mobile: Yup.string().required(i18n.t('Mobile number is required!'))
                .matches(/^[0-9]{6,15}$/, "Mobile number should be between 6 to 15 digit only."),
            email: Yup.string().email(i18n.t('Invalid Email address')).required(i18n.t('Email is required!')),
            password: Yup.string()
                .nullable()
                .min(8, (i18n.t('Password must be 8 character length'))),
            password_confirmation: Yup.string()
                .nullable()
                .oneOf([Yup.ref('password'), null], (i18n.t('Passwords must match'))),
            role: Yup.string().required(i18n.t('Role is required!')),


            company_name: Yup.string().required(i18n.t('Company name is required!')).matches(/^[a-zA-Z_ ]*$/, "Should contain only alphabets"),
            company_contact_person: Yup.string().required(i18n.t('Company contact person is required!')).matches(/^[a-zA-Z_ ]*$/, "Should contain only alphabets"),
            account_number: Yup.string().nullable(),
            ifsc_code: Yup.string()
                .nullable()
                .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "follow this format: SBIN0005943"),
            branch_name: Yup.string().nullable(),
            tin_no: Yup.string().nullable()
                .min(11, (i18n.t('TIN number should be 11 digit')))
                .max(11, (i18n.t('TIN number should be 11 digit'))),
            cst_no: Yup.string().nullable()
                .min(11, (i18n.t('CST number should be 11 digit')))
                .max(11, (i18n.t('CST number should be 11 digit'))),
            pan_no: Yup.string().nullable()
                .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "follow this format: ABCTY1234D"),
            phone: Yup.string().nullable()
                .min(10, (i18n.t('should be 10 digit')))
                .max(10, (i18n.t('should be 10 digit'))),

            account_number: Yup.string().nullable(),
            bank_name: Yup.string().nullable(),
            branch_name: Yup.string().nullable(),
            swift_code: Yup.string().nullable(),
            branch_code: Yup.string().nullable(),
            international_bank_code: Yup.string().nullable(),
            national_bank_code: Yup.string().nullable(),
            gst: Yup.string().nullable()
        });

    } else if (location.state.UserData && location.state.UserData.role_name === "customer") {

        validationSchema = Yup.object().shape({

            first_name: Yup.string().required(i18n.t('First name is required!')).matches(/^[a-zA-Z_ ]*$/, "Should contain only alphabets"),
            last_name: Yup.string().required(i18n.t('Last name is required!')).matches(/^[a-zA-Z_ ]*$/, "Should contain only alphabets"),
            gender: Yup.string().required(i18n.t('Gender is required!')),
            birth_date: Yup.string().required(i18n.t('DOB is required!')),
            address: Yup.string().required(i18n.t('Address is required!')),
            city: Yup.string().required(i18n.t('City is required!')),
            state: Yup.string().required(i18n.t('State is required!')),
            pincode: Yup.string().required(i18n.t('Pincode is required!'))
                .matches(/^[0-9]{0,10}$/, "Pincode should not be more than 10 digits."),
            mobile: Yup.string().required(i18n.t('Mobile number is required!'))
                .matches(/^[0-9]{6,15}$/, "Mobile number should be between 6 to 15 digit only."),
            email: Yup.string().email(i18n.t('Invalid Email address')).required(i18n.t('Email is required!')),
            password: Yup.string()
                .nullable()
                .min(8, (i18n.t('Password must be 8 character length'))),
            password_confirmation: Yup.string()
                .nullable()
                .oneOf([Yup.ref('password'), null], (i18n.t('Passwords must match'))),
            role: Yup.string().required(i18n.t('Role is required!')),


            company_name: Yup.string().required(i18n.t('Company name is required!')).matches(/^[a-zA-Z_ ]*$/, "Should contain only alphabets"),
            company_contact_person: Yup.string().required(i18n.t('Company contact person is required!')).matches(/^[a-zA-Z_ ]*$/, "Should contain only alphabets"),
            account_number: Yup.string().nullable(),
            ifsc_code: Yup.string()
                .nullable()
                .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "follow this format: SBIN0005943"),
            branch_name: Yup.string().nullable(),
            tin_no: Yup.string().nullable()
                .min(11, (i18n.t('TIN number should be 11 digit')))
                .max(11, (i18n.t('TIN number should be 11 digit'))),
            cst_no: Yup.string().nullable()
                .min(11, (i18n.t('CST number should be 11 digit')))
                .max(11, (i18n.t('CST number should be 11 digit'))),
            pan_no: Yup.string().nullable()
                .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "follow this format: ABCTY1234D"),
            phone: Yup.string().nullable()
                .min(10, (i18n.t('should be 10 digit')))
                .max(10, (i18n.t('should be 10 digit'))),

            OtherContact: Yup.array().of(
                Yup.object().shape({
                    MobileNumber: Yup.string()
                        .min(10, (i18n.t('mobile number should be 10 digit')))
                        .max(10, (i18n.t('mobile number should be 10 digit')))
                        .nullable()
                })
            ),

        });
    } else {

        validationSchema = Yup.object().shape({
            first_name: Yup.string().required(i18n.t('First name is required!')).matches(/^[a-zA-Z_ ]*$/, "Should contain only alphabets"),
            last_name: Yup.string().required(i18n.t('Last name is required!')).matches(/^[a-zA-Z_ ]*$/, "Should contain only alphabets"),
            gender: Yup.string().required(i18n.t('Gender is required!')),
            birth_date: Yup.string().required(i18n.t('DOB is required!')),
            address: Yup.string().required(i18n.t('Address is required!')),
            city: Yup.string().required(i18n.t('City is required!')),
            state: Yup.string().required(i18n.t('State is required!')),
            pincode: Yup.string().required(i18n.t('Pincode is required!'))
                .matches(/^[0-9]{0,10}$/, "Pincode should not be more than 10 digits."),
            mobile: Yup.string().required(i18n.t('Mobile number is required!'))
                .matches(/^[0-9]{6,15}$/, "Mobile number should be between 6 to 15 digit only."),
            email: Yup.string().email(i18n.t('Invalid Email address')).required(i18n.t('Email is required!')),
            password: Yup.string()
                .nullable()
                .min(8, (i18n.t('Password must be 8 character length'))),
            password_confirmation: Yup.string()
                .nullable()
                .oneOf([Yup.ref('password'), null], (i18n.t('Passwords must match'))),
            role: Yup.string().required(i18n.t('Role is required!')),


            profile: Yup.mixed()
                .nullable()
                .notRequired()
                .test(
                    "fileSize",
                    "File size too large, max file size is 2.048 Mb",
                    (file) => {
                        if (file) {
                            return file.size <= 2048000;
                        } else {
                            return true;
                        }
                    }
                )
                .test(
                    "FILE_FORMAT",
                    i18n.t("uploaded file has unsupported file format"),
                    (value) => !value || [
                        "image/jpg",
                        "image/jpeg",
                        "image/gif",
                        "image/png",
                    ].includes(value?.type)
                ),

        });

    }

    const UserRole = [
        {
            value: 'employee',
            label: i18n.t('Employee'),
        },
        {
            value: 'customer',
            label: i18n.t('Customer'),
        },
        {
            value: 'supplier',
            label: i18n.t('Supplier'),
        },
    ];

    // const useStyles = makeStyles((theme: Theme) => createStyles({
    //     datePicker: {
    //         '& .MuiPickersCalendarHeader-label': {
    //             fontFamily: 'poppins !important',
    //             color: '#333333',
    //             fontWeight: '400',
    //             fontSize: '15px'
    //         },
    //         '& .MuiDayPicker-weekDayLabel': {
    //             fontFamily: 'poppins !important',
    //             color: '#818386',
    //             fontWeight: '400',
    //             fontSize: '15px'
    //         },
    //         '& .MuiPickersDay-root': {
    //             fontFamily: 'poppins !important',
    //             color: '#333333',
    //             fontWeight: '400',
    //             fontSize: '13px'
    //         },
    //         '& .MuiDayPicker-weekContainer': {
    //             fontFamily: 'poppins !important',
    //             color: '#333333',
    //             fontWeight: '400',
    //             fontSize: '15px'
    //         },
    //         '& .MuiPickersDay-root.Mui-selected': {
    //             fontFamily: 'poppins !important',
    //             color: '#fff',
    //             backgroundColor: '#01C580',
    //         },
    //         '& .MuiButton-root': {
    //             fontFamily: 'poppins !important',
    //             color: '#1976D2',
    //             fontWeight: '500',
    //             fontSize: '15px'
    //         },
    //         '& .css-wyb1mi-PrivatePickersYear-button': {
    //             fontFamily: 'poppins !important',
    //             color: '#333333',
    //             fontWeight: '500',
    //             fontSize: '17px'
    //         },
    //         '& .css-wyb1mi-PrivatePickersYear-button.Mui-selected': {
    //             fontFamily: 'poppins !important',
    //             color: '#fff',
    //             backgroundColor: '#01C580'
    //         }
    //     }
    // }));
    // const { datePicker } = useStyles();

    // Datatable options

    const options = {
        search: false,
        download: false,
        print: false,
        viewColumns: false,
        filter: false,
        order: false,
        selectableRows: false,
        elevation: 0,
        responsive: 'standard',
        filterType: 'dropdown',
        fixedHeader: true,
        fixedSelectColumn: false,
        tableBodyHeight: '100%',
        enableNestedDataAccess: '.',

        //For localization 
        textLabels: {
            body: {
                noMatch: i18n.t("Sorry, no matching records found"),
                toolTip: i18n.t("Sort"),
                columnHeaderTooltip: column => `${column.label}` + " " + i18n.t("Sort for")
            },
            pagination: {
                next: i18n.t("Next Page"),
                previous: i18n.t("Previous Page"),
                rowsPerPage: i18n.t("Rows per page"),
                displayRows: i18n.t("of"),
            },
            toolbar: {
                search: i18n.t("Search"),
                downloadCsv: i18n.t("Download CSV"),
                print: i18n.t("Print"),
                viewColumns: i18n.t("View Columns"),
                filterTable: i18n.t("Filter Table"),
            },
            filter: {
                all: i18n.t("All"),
                title: i18n.t("FILTERS"),
                reset: i18n.t("RESET"),
            },
            viewColumns: {
                title: i18n.t("Show Columns"),
                titleAria: i18n.t("Show/Hide Table Columns"),
            },
            selectedRows: {
                text: i18n.t("row(s) selected"),
                delete: i18n.t("Delete"),
                deleteAria: i18n.t("Delete Selected Rows"),
            },
        },
    };

    // Sales Datatable columns
    const Salecolumns = [
        {
            name: "id", label: "id", options: {
                display: false
            }
        },
        {
            name: "sale_no", label: i18n.t("Bill Number").toUpperCase(),
            options: {
                setCellHeaderProps: () => ({
                    style: {
                        width: '100px'
                    }
                }),
            }
        },
        {
            name: "customer.full_name", label: i18n.t("Customer Name").toUpperCase(),
            options: {
                setCellHeaderProps: () => ({
                    style: {
                        width: '150px'
                    }
                }),
            }
        },
        {
            name: "date", label: i18n.t("Date").toUpperCase(),
            options: {
                setCellHeaderProps: () => ({
                    style: {
                        width: '80px'
                    }
                }),
                customBodyRender: (date, meta: MUIDataTableMeta) => {
                    return (
                        <>{moment(date, 'DD-MM-YYYY').format(window.localStorage.getItem('dateFormat'))}</>
                    );
                },
            },
        },
        {
            name: "customer.address", label: i18n.t("Billing Address").toUpperCase(),
            options: {
                setCellHeaderProps: () => ({
                    style: {
                        width: '150px'
                    }
                }),
            }
        },
        {
            name: "status", label: i18n.t("Status").toUpperCase(),
            options: {
                setCellHeaderProps: () => ({
                    style: {
                        width: '80px'
                    }
                }),
                customBodyRender: (status, meta: MUIDataTableMeta) => {
                    return (
                        i18n.t(status)
                    );
                },
            },
        },
        {
            name: "discount", label: i18n.t("Discount").toUpperCase() + "(%)",
            options: {
                setCellHeaderProps: () => ({
                    style: {
                        width: '120px'
                    }
                }),
            }
        },

    ]

    const Quotationcolumns = [
        {
            name: "id", label: "id", options: {
                display: false
            }
        },
        {
            name: "customer.full_name", label: i18n.t('Customer Name').toUpperCase(),
            options: {
                setCellHeaderProps: () => ({
                    style: {
                        width: '150px',
                    }
                }),
                customBodyRender: (name, meta: MUIDataTableMeta) => {
                    return (
                        <Grid container>
                            <Grid item xs={12}>
                                <div style={{ lineHeight: '20px', fontWeight: 'bold' }}>{name}</div>
                                <div style={{ lineHeight: '20px', fontSize: '12px' }}>{usersData.userDetails.quotation[meta.rowIndex].quotation_no}</div>
                            </Grid>
                        </Grid>
                    );
                },
            },
        },
        {
            name: "products", label: i18n.t("Product Name").toUpperCase(),
            options: {
                setCellHeaderProps: () => ({
                    style: {
                        width: '130px'
                    }
                }),
                customBodyRender: (products, meta: MUIDataTableMeta) => {
                    return (
                        <Grid container>
                            {products.slice(0, 1).map((data, index) => (
                                <Grid Grid item xs={12} >
                                    <div style={{ lineHeight: '20px', fontWeight: 'bold' }}>{data.product_name}
                                        <span style={{ marginLeft: '5px', marginTop: '3px' }}>
                                            {products.length >= 2 ? (
                                                <Tooltip title={
                                                    <>
                                                        {products.slice(1,).map((data, index) => (
                                                            <Grid item xs={12}>
                                                                <div>{data.product_name}</div>
                                                                <div>{window.localStorage.getItem('Currency') + " " + data.amount}</div>
                                                            </Grid>
                                                        ))}
                                                    </>
                                                } placement="right-start" arrow>
                                                    <img src={Tooltip_Vector} alt="" className="element" />
                                                </Tooltip>
                                            ) : (<></>)}
                                        </span></div>
                                    <div style={{ lineHeight: '20px', fontSize: '12px' }}>{window.localStorage.getItem('Currency') + " " + data.amount}</div>
                                </Grid>
                            ))
                            }
                        </Grid >
                    );
                },
            }
        },
        {
            name: "gross_total", label: i18n.t("Gross Total").toUpperCase(),
            options: {
                setCellHeaderProps: () => ({
                    style: {
                        width: '110px'
                    }
                }),
                customBodyRender: (gross_total, meta: MUIDataTableMeta) => {
                    return (
                        <>{window.localStorage.getItem('Currency') + " " + gross_total}</>
                    );
                },
            }
        },
        {
            name: "date", label: i18n.t("Date").toUpperCase(),
            options: {
                setCellHeaderProps: () => ({
                    style: {
                        width: '100px'
                    }
                }),
                customBodyRender: (date, meta: MUIDataTableMeta) => {
                    return (
                        <>{moment(date, 'DD-MM-YYYY').format(window.localStorage.getItem('dateFormat'))}</>
                    );
                },
            },
        },
        {
            name: "customer.email", label: i18n.t("Email").toUpperCase(),
            options: {
                setCellHeaderProps: () => ({
                    style: {
                        width: '260px'
                    }
                }),
            }
        },
        {
            name: "status", label: i18n.t("Status").toUpperCase(),
            options: {
                setCellHeaderProps: () => ({
                    style: {
                        width: '70px'
                    }
                }),
                customBodyRender: (status, meta: MUIDataTableMeta) => {
                    return (
                        i18n.t(status)
                    );
                },
            },
        },
    ]

    const Complaintcolumns = [
        {
            name: "id", label: "id", options: {
                display: false
            }
        },
        // {
        //     name: "complaint_no", label: i18n.t("Complaint Number"),
        // },
        {
            name: "customer.full_name", label: i18n.t("Customer Name").toUpperCase(),
            options: {
                setCellHeaderProps: () => ({
                    style: {
                        width: '150px'
                    }
                }),
                customBodyRender: (name, meta: MUIDataTableMeta) => {
                    return (
                        <Grid container>
                            <Grid item xs={12}>
                                <div style={{ lineHeight: '20px', fontWeight: 'bold' }}>{name}</div>
                                <div style={{ lineHeight: '20px', fontSize: '12px' }}>{usersData.userDetails.complaint[meta.rowIndex].complaint_no}</div>
                            </Grid>
                        </Grid>
                    );
                },
            },
        },
        {
            name: "product.name", label: i18n.t("Product Name").toUpperCase(),
            options: {
                setCellHeaderProps: () => ({
                    style: {
                        width: '120px'
                    }
                }),
            }
        },
        {
            name: "date", label: i18n.t("Complaint Date").toUpperCase(),
            options: {
                setCellHeaderProps: () => ({
                    style: {
                        width: '150px'
                    }
                }),
                customBodyRender: (date, meta: MUIDataTableMeta) => {
                    return (
                        <>{moment(date, 'DD-MM-YYYY').format(window.localStorage.getItem('dateFormat'))}</>
                    );
                },
            },
        },
        {
            name: "complaint_type", label: i18n.t("Complaint Type").toUpperCase(),
            options: {
                setCellHeaderProps: () => ({
                    style: {
                        width: '150px'
                    }
                }),
            }
        },
        {
            name: "assign_to.full_name", label: i18n.t("Assign To").toUpperCase(),
            options: {
                setCellHeaderProps: () => ({
                    style: {
                        width: '120px'
                    }
                }),
            }
        },
        {
            name: "status", label: i18n.t("Status").toUpperCase(),
            options: {
                setCellHeaderProps: () => ({
                    style: {
                        width: '80px'
                    }
                }),
                customBodyRender: (status, meta: MUIDataTableMeta) => {
                    return (
                        i18n.t(status)
                    );
                },
            },
        },

    ]

    const Purchasecolumns = [
        {
            name: "id", label: "id", options: {
                display: false
            }
        },
        {
            name: "purchase_no", label: i18n.t("Purchase No.").toUpperCase(),
            options: {
                setCellHeaderProps: () => ({
                    style: {
                        width: '130px',
                    }
                }),
                customBodyRender: (purchase_no, meta: MUIDataTableMeta) => {
                    return (
                        <a style={{ cursor: 'pointer' }}>{purchase_no}</a>
                    );
                },
            },
        },
        {
            name: "supplier.full_name", label: i18n.t("supplier").toUpperCase(),
            options: {
                setCellHeaderProps: () => ({
                    style: {
                        width: '120px',
                    }
                }),
            }
        },
        {
            name: "date", label: i18n.t("Date").toUpperCase(),
            options: {
                setCellHeaderProps: () => ({
                    style: {
                        width: '120px',
                    }
                }),
                customBodyRender: (date, meta: MUIDataTableMeta) => {
                    return (
                        <>{moment(date, 'DD-MM-YYYY').format(window.localStorage.getItem('dateFormat'))}</>
                    );
                },
            },
        },
        {
            name: "supplier.address", label: i18n.t("Billing Address").toUpperCase(),
            options: {
                setCellHeaderProps: () => ({
                    style: {
                        width: '150px',
                    }
                }),
            }
        },
        {
            name: "status", label: i18n.t("Status").toUpperCase(), options: {
                setCellHeaderProps: () => ({
                    style: {
                        width: '120px',
                    }
                }),
                customBodyRender: (status, meta: MUIDataTableMeta) => {
                    return (
                        i18n.t(status)
                    );
                },
            },
        },
    ];
    return (
        <Container>
            {usersData !== undefined ? (
                <>
                    <div className="userprofilecard">
                        <Grid container>
                            <Grid item xs={5} sm={3} md={3} lg={1.8} xl={1.8}>
                                {usersData.userDetails.profile !== undefined ? (
                                    <div><img className="userimage" src={usersData.userDetails.profile} /></div>
                                ) : (<></>)}
                            </Grid>
                            <Grid item xs={7} sm={9} md={9} lg={9.2} xl={9.2}>
                                {usersData.userDetails.full_name !== undefined ? (
                                    <>
                                        {Permission === null || Permission.some((item) => item.name === 'user_edit') ? (
                                            <Headerusername>{usersData.userDetails.full_name} <span style={{ padding: i18n.language === 'ar' ? '10px' : '' }}><img onClick={() => handleDrawerToggle()} className='usereditimage' src={Edit_Button} /></span></Headerusername>
                                        ) : (
                                            <Headerusername>{usersData.userDetails.full_name} <span style={{ padding: i18n.language === 'ar' ? '10px' : '' }}></span></Headerusername>
                                        )}
                                    </>
                                ) : (<></>)}
                                <Grid container spacing={0}>
                                    <Grid item xs={12} sm={2.5} md={2.5} lg={2.5} xl={2.5}>
                                        <Headeruserdetails><img src={Call_Vector} /> +{window.localStorage.getItem('countryCode')} {usersData.userDetails.mobile}</Headeruserdetails>
                                    </Grid>
                                    <Grid item xs={12} sm={9.5} md={9.5} lg={9.5} xl={9.5}>
                                        <Headeruserdetails><img className='useremailimage' src={Email_Vector} /> {usersData.userDetails.email}</Headeruserdetails>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Headeruseraddress> <img src={Location} /> {usersData.userDetails.address}, {usersData.userDetails.city}, {usersData.userDetails.state} - {usersData.userDetails.pincode}</Headeruseraddress>
                    </div>

                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                {usersData.userDetails.role_name === "customer" ? (
                                    <TabList onChange={handleTabChange} aria-label="lab API tabs example" variant="scrollable" TabIndicatorProps={{
                                        style: {
                                            // background: "#01C681",
                                            background: "#103C64",
                                            height: "4px", top: "45px",
                                        }
                                    }}
                                    >
                                        <Tab label={i18n.t("General")} className="input_props" value="1" />
                                        <Tab label={i18n.t("Sales")} className="input_props" value="2" />
                                        <Tab label={i18n.t("Quotations")} className="input_props" value="3" />
                                        <Tab label={i18n.t("Complaints")} className="input_props" value="4" />
                                    </TabList>
                                ) : usersData.userDetails.role_name === "employee" ? (
                                    <TabList onChange={handleTabChange} aria-label="lab API tabs example" variant="scrollable" TabIndicatorProps={{
                                        style: {
                                            // background: "#01C681",
                                            background: "#103C64",
                                            height: "4px", top: "45px",
                                        }
                                    }}>
                                        <Tab label={i18n.t("General")} className="input_props" value="1" />
                                        <Tab label={i18n.t("Complaints")} className="input_props" value="4" />
                                    </TabList>
                                ) : (
                                    <TabList onChange={handleTabChange} aria-label="lab API tabs example" variant="scrollable" TabIndicatorProps={{
                                        style: {
                                            //  background: "#01C681",
                                            background: "#103C64",
                                            height: "4px", top: "45px",
                                        }
                                    }}>
                                        <Tab label={i18n.t("General")} className="input_props" value="1" />
                                        <Tab label={i18n.t("Purchase")} className="input_props" value="5" />
                                    </TabList>
                                )}
                            </Box>
                            <TabPanel value="1">
                                <Grid container spacing={2}>
                                    <Grid item xs={6} sm={4} md={4} lg={3} xl={3}>
                                        {usersData.userDetails.role_name === "customer" ? (
                                            <Generaluserdetails>{i18n.t('Customer ID')}</Generaluserdetails>
                                        ) : usersData.userDetails.role_name === "employee" ? (
                                            <Generaluserdetails>{i18n.t('Employee ID')}</Generaluserdetails>
                                        ) : (
                                            <Generaluserdetails>{i18n.t('Supplier ID')}</Generaluserdetails>
                                        )}
                                        <GeneraluserData>{usersData.userDetails.unique_identity}</GeneraluserData>
                                    </Grid>
                                    <Grid item xs={6} sm={4} md={4} lg={3} xl={3}>
                                        <Generaluserdetails>{i18n.t('Date of Birth')}</Generaluserdetails>
                                        <GeneraluserData>{moment(usersData.userDetails.birth_date).format('DD-MM-YYYY')}</GeneraluserData>
                                    </Grid>
                                    <Grid item xs={6} sm={4} md={4} lg={3} xl={3}>
                                        <Generaluserdetails>{i18n.t('Gender')}</Generaluserdetails>
                                        <GeneraluserData>{usersData.userDetails.gender}</GeneraluserData>
                                    </Grid>
                                    {usersData.userDetails.role_name !== "employee" ? (
                                        <Grid item xs={6} sm={4} md={4} lg={3} xl={3}>
                                            <Generaluserdetails>{i18n.t('Company')}</Generaluserdetails>
                                            <GeneraluserData>{usersData.userDetails.company_name}</GeneraluserData>
                                        </Grid>
                                    ) : (<></>)}
                                </Grid>
                                {usersData.userDetails.role_name === "customer" ? (
                                    <>
                                        <Grid container spacing={2} className="cardGrid">
                                            <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                                <Card sx={{ px: 3, py: 2, mb: 3 }} className="companycarddetails">

                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} lg={12} md={12} sm={12}>
                                                            <Title className='cardheader'>{i18n.t('Company Details')}</Title>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                            <Generaluserlabel>{i18n.t('Main Person Name')}: <GeneraluserOtherData>{usersData.userDetails.company_contact_person}</GeneraluserOtherData></Generaluserlabel>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                            <Generaluserlabel>{i18n.t('Company Name')}: <GeneraluserOtherData>{usersData.userDetails.company_name}</GeneraluserOtherData></Generaluserlabel>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                            <Generaluserlabel>{i18n.t('Account Number')}: {usersData.userDetails.account_number !== "" ? <GeneraluserOtherData>{usersData.userDetails.account_number}</GeneraluserOtherData> : <GeneraluserOtherData>N/A</GeneraluserOtherData>}</Generaluserlabel>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                            <Generaluserlabel>{i18n.t('IFS Code')}: {usersData.userDetails.ifsc_code !== "" ? <GeneraluserOtherData>{usersData.userDetails.ifsc_code}</GeneraluserOtherData> : <GeneraluserOtherData>N/A</GeneraluserOtherData>}</Generaluserlabel>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                            <Generaluserlabel>{i18n.t('Branch Name')}: {usersData.userDetails.branch_name !== "" ? <GeneraluserOtherData>{usersData.userDetails.branch_name}</GeneraluserOtherData> : <GeneraluserOtherData>N/A</GeneraluserOtherData>}</Generaluserlabel>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                            <Generaluserlabel>{i18n.t('TIN No')}: {usersData.userDetails.tin_no !== "" ? <GeneraluserOtherData>{usersData.userDetails.tin_no}</GeneraluserOtherData> : <GeneraluserOtherData>N/A</GeneraluserOtherData>}</Generaluserlabel>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                            <Generaluserlabel>{i18n.t('CST No')}: {usersData.userDetails.cst_no !== "" ? <GeneraluserOtherData>{usersData.userDetails.cst_no}</GeneraluserOtherData> : <GeneraluserOtherData>N/A</GeneraluserOtherData>}</Generaluserlabel>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                            <Generaluserlabel>{i18n.t('PAN No')}: {usersData.userDetails.pan_no !== "" ? <GeneraluserOtherData>{usersData.userDetails.pan_no}</GeneraluserOtherData> : <GeneraluserOtherData>N/A</GeneraluserOtherData>} </Generaluserlabel>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                            <Generaluserlabel>{i18n.t('Mobile Number')}: <GeneraluserOtherData>+{window.localStorage.getItem('countryCode')} {usersData.userDetails.mobile}</GeneraluserOtherData></Generaluserlabel>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} lg={12} md={12} sm={12} className="otherchartheaderCss">
                                                            <Title className='cardheader'>{i18n.t('Other Information')}</Title>
                                                        </Grid>
                                                    </Grid>
                                                    {usersData.userDetails.customer_contacts !== undefined && usersData.userDetails.customer_contacts !== null ? (
                                                        <>
                                                            {usersData.userDetails.customer_contacts.map((Data) => (
                                                                <>
                                                                    <Grid container spacing={1}>
                                                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                                            <Generaluserlabel>{i18n.t('Person Name')}: <GeneraluserOtherData>{Data.contact_person}</GeneraluserOtherData></Generaluserlabel>
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                                            <Generaluserlabel>{i18n.t('Designation')}: <GeneraluserOtherData>{Data.designation}</GeneraluserOtherData></Generaluserlabel>
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Grid container spacing={1}>
                                                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                                            <Generaluserlabel>{i18n.t('Number')}: <GeneraluserOtherData>+{window.localStorage.getItem('countryCode')} {Data.mobile}</GeneraluserOtherData></Generaluserlabel>
                                                                        </Grid>
                                                                    </Grid>
                                                                </>
                                                            ))}
                                                        </>
                                                    ) : (
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                                <NoDataContainer>{i18n.t('No Data available')}</NoDataContainer>
                                                            </Grid>
                                                        </Grid>)}

                                                </Card>
                                            </Grid>
                                            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                                <Card sx={{ px: 3, py: 2, mb: 3 }} className="othercarddetails">

                                                    <Grid container spacing={1}>
                                                        <Grid lg={12} md={12} sm={12} xs={12} className="chartheaderCss">
                                                            <Title className='cardheader'>{i18n.t('Recent Complaints')}</Title>
                                                        </Grid>

                                                        <Grid container spacing={1}>
                                                            {usersData.userDetails.complaint !== undefined && usersData.userDetails.complaint !== null && usersData.userDetails.complaint.map((item) => (
                                                                <>
                                                                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                                                        <ComplaintSecondDate>{moment(item.date, 'DD-MM-YYYY').format('MMM. DD,YYYY')}</ComplaintSecondDate>
                                                                    </Grid>
                                                                    <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                                                        <Generaluserlabel>To. {item.assign_to.full_name}</Generaluserlabel>
                                                                        <ComplaintuserData>{item.complaint_type}</ComplaintuserData>
                                                                    </Grid>
                                                                </>
                                                            ))}
                                                            {/* (<NoDataContainer>No Data available</NoDataContainer>)} */}

                                                        </Grid>
                                                    </Grid>

                                                </Card>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                            </Grid>
                                            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                                <Card sx={{ px: 3, py: 2, mb: 3 }} className="secondothercarddetails">

                                                    <Grid container spacing={1}>
                                                        <Grid lg={12} md={12} sm={12} xs={12} className="chartheaderCss">
                                                            <Title className='cardheader'>{i18n.t('Upcoming Services')}</Title>
                                                        </Grid>
                                                    </Grid>
                                                    {usersData.userDetails.services !== undefined && usersData.userDetails.services !== null && usersData.userDetails.services.map((item, index) => (

                                                        <Grid container spacing={1}>
                                                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                                                <ServiceSecondDate>{moment(item.service_date, 'DD-MM-YYYY').format('MMM. DD,YYYY')}</ServiceSecondDate>
                                                            </Grid>
                                                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                                                <Generaluserlabel>{item.assignTo.full_name}</Generaluserlabel>
                                                                <ComplaintuserData>{item.service_label}</ComplaintuserData>
                                                            </Grid>
                                                        </Grid>
                                                    ))}
                                                    {/* ) : (<NoDataContainer>No Data available</NoDataContainer>)} */}

                                                </Card>
                                            </Grid>
                                        </Grid>
                                    </>
                                ) : usersData.userDetails.role_name === "employee" ? (
                                    <>
                                        <Grid container spacing={2} className="cardGrid">
                                            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                                <Card sx={{ px: 3, py: 2, mb: 3 }} className="othercarddetails">

                                                    <Grid container spacing={1}>
                                                        <Grid lg={12} md={12} sm={12} xs={12} className="chartheaderCss">
                                                            <Title className='cardheader'>{i18n.t('Recent Complaints')}</Title>
                                                        </Grid>

                                                        <Grid container spacing={1}>
                                                            {usersData.userDetails.complaint !== undefined && usersData.userDetails.complaint !== null && usersData.userDetails.complaint.map((item) => (
                                                                <>
                                                                    <Grid item xs={4} sm={2} md={4} lg={4} xl={4}>
                                                                        <ComplaintSecondDate>{moment(item.date, 'DD-MM-YYYY').format('MMM. DD,YYYY')}</ComplaintSecondDate>
                                                                    </Grid>
                                                                    <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                                                        <Generaluserlabel>To. {item.customer.full_name}</Generaluserlabel>
                                                                        <ComplaintuserData>{item.complaint_type}</ComplaintuserData>
                                                                    </Grid>
                                                                </>
                                                            ))}
                                                            {/* (<NoDataContainer>No Data available</NoDataContainer>)} */}

                                                        </Grid>
                                                    </Grid>

                                                </Card>
                                            </Grid>

                                            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                                <Card sx={{ px: 3, py: 2, mb: 3 }} className="employeesecondothercarddetails">

                                                    <Grid container spacing={1}>
                                                        <Grid lg={12} md={12} sm={12} xs={12} className="chartheaderCss">
                                                            <Title className='cardheader'>{i18n.t('Upcoming Services')}</Title>
                                                        </Grid>
                                                    </Grid>
                                                    {usersData.userDetails.service !== undefined && usersData.userDetails.service !== null && usersData.userDetails.service.map((item, index) => (

                                                        <Grid container spacing={1}>
                                                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                                                <ServiceSecondDate>{moment(item.service_date, 'DD-MM-YYYY').format('MMM. DD,YYYY')}</ServiceSecondDate>
                                                            </Grid>
                                                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                                                <Generaluserlabel>{item.amc.customer.full_name}</Generaluserlabel>
                                                                <ComplaintuserData>{item.service_label}</ComplaintuserData>
                                                            </Grid>
                                                        </Grid>
                                                    ))}

                                                </Card>
                                            </Grid>

                                        </Grid>

                                    </>) : (
                                    <>
                                        <Grid container spacing={2} className="cardGrid">

                                            <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                                <Card sx={{ px: 3, py: 2, mb: 3 }} className="companycarddetails">

                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} lg={12} md={12} sm={12} className="chartheaderCss">
                                                            <Title className='cardheader'>{i18n.t('Company Details')}</Title>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                            <Generaluserlabel>{i18n.t('Main Person Name')}: <GeneraluserOtherData>{usersData.userDetails.company_contact_person}</GeneraluserOtherData></Generaluserlabel>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                            <Generaluserlabel>{i18n.t('Company Name')}: <GeneraluserOtherData>{usersData.userDetails.company_name}</GeneraluserOtherData></Generaluserlabel>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                            <Generaluserlabel>{i18n.t('Account Number')}:{usersData.userDetails.account_number !== "" ? <GeneraluserOtherData>{usersData.userDetails.account_number}</GeneraluserOtherData> : <GeneraluserOtherData>N/A</GeneraluserOtherData>}</Generaluserlabel>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                            <Generaluserlabel>{i18n.t('IFS Code')}: {usersData.userDetails.ifsc_code !== "" ? <GeneraluserOtherData>{usersData.userDetails.ifsc_code}</GeneraluserOtherData> : <GeneraluserOtherData>N/A</GeneraluserOtherData>}</Generaluserlabel>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                            <Generaluserlabel>{i18n.t('Branch Name')}: {usersData.userDetails.branch_name !== "" ? <GeneraluserOtherData>{usersData.userDetails.branch_name}</GeneraluserOtherData> : <GeneraluserOtherData>N/A</GeneraluserOtherData>}</Generaluserlabel>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                            <Generaluserlabel>{i18n.t('TIN No')}: {usersData.userDetails.tin_no !== "" ? <GeneraluserOtherData>{usersData.userDetails.tin_no}</GeneraluserOtherData> : <GeneraluserOtherData>N/A</GeneraluserOtherData>}</Generaluserlabel>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                            <Generaluserlabel>{i18n.t('CST No')}: {usersData.userDetails.cst_no !== "" ? <GeneraluserOtherData>{usersData.userDetails.cst_no}</GeneraluserOtherData> : <GeneraluserOtherData>N/A</GeneraluserOtherData>}</Generaluserlabel>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                            <Generaluserlabel>{i18n.t('PAN No')}: {usersData.userDetails.pan_no !== "" ? <GeneraluserOtherData>{usersData.userDetails.pan_no}</GeneraluserOtherData> : <GeneraluserOtherData>N/A</GeneraluserOtherData>} </Generaluserlabel>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                            <Generaluserlabel>{i18n.t('Mobile Number')}: <GeneraluserOtherData>+{window.localStorage.getItem('countryCode')} {usersData.userDetails.mobile}</GeneraluserOtherData></Generaluserlabel>
                                                        </Grid>
                                                    </Grid>
                                                </Card>
                                            </Grid>

                                            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                                <Card sx={{ px: 3, py: 2, mb: 3 }} className="othercarddetails">

                                                    <Grid container spacing={1}>
                                                        <Grid lg={12} md={12} sm={12} xs={12} className="chartheaderCss">
                                                            <Title className='cardheader'>{i18n.t('Purchase List')}</Title>
                                                        </Grid>
                                                    </Grid>
                                                    {usersData.userDetails.purchase !== undefined && usersData.userDetails.purchase !== null && usersData.userDetails.purchase.map((item, index) => (

                                                        <Grid container spacing={1}>
                                                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                                                <ServiceSecondDate>{moment(item.date, 'DD-MM-YYYY').format('MMM. DD,YYYY')}</ServiceSecondDate>
                                                            </Grid>
                                                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                                                <Generaluserlabel>{item.supplier.full_name}</Generaluserlabel>
                                                                <ComplaintuserData>{item.purchase_no}</ComplaintuserData>
                                                            </Grid>
                                                        </Grid>
                                                    ))}
                                                    {/* ) : (<NoDataContainer>No Data available</NoDataContainer>)} */}

                                                </Card>
                                            </Grid>

                                        </Grid>
                                    </>

                                )}

                            </TabPanel>
                            <TabPanel value="2">
                                <ThemeProvider
                                    theme={(baseTheme: Theme) =>
                                        createTheme({
                                            ...baseTheme,
                                            components: {
                                                ...baseTheme.components,
                                                MUIDataTableToolbar: {
                                                    styleOverrides: {
                                                        icon: {
                                                            color: '#000000',
                                                            '&:hover': {
                                                                // color: "#01C681"
                                                                color: "#103C64"
                                                            },
                                                            backgroundColor: "#F2F5FA",
                                                            height: '50px',
                                                            width: '50px',
                                                            borderRadius: '0px',
                                                            margin: '5px',
                                                        },
                                                        iconActive: {

                                                        }
                                                    },
                                                },
                                                MUIDataTableHeadCell: {
                                                    styleOverrides: {
                                                        fixedHeader: {
                                                            fontWeight: "600",
                                                            fontFamily: "poppins",
                                                            lineHeight: '21px',
                                                            fontSize: '14px',
                                                            color: '#ADAEB0',
                                                        }
                                                    }
                                                },
                                                MUIDataTableSelectCell: {
                                                    styleOverrides: {
                                                        fixedHeader: {
                                                            fontFamily: "poppins",
                                                            lineHeight: '15px',
                                                            color: '#333333',
                                                        }
                                                    }
                                                },
                                                MuiButton: {
                                                    styleOverrides: {
                                                        root: {
                                                            fontWeight: "600",
                                                            fontFamily: "poppins",
                                                            lineHeight: '21px',
                                                            fontSize: '14px',
                                                            color: '#ADAEB0',
                                                        },
                                                    },
                                                },
                                                MUIDataTableBodyCell: {
                                                    styleOverrides: {
                                                        root: {
                                                            fontFamily: "poppins",
                                                            lineHeight: '15px',
                                                            fontSize: '14px',
                                                            fontWeight: "400",
                                                            color: '#595F69',
                                                        }
                                                    }
                                                },
                                                MuiTooltip: {
                                                    styleOverrides: {
                                                        root: {
                                                            fontFamily: "poppins",
                                                            lineHeight: '15px',
                                                            fontSize: '14px',
                                                            fontWeight: "400",
                                                            color: 'red',
                                                        }
                                                    }
                                                },
                                                MUIDataTable: {
                                                    styleOverrides: {
                                                        responsiveBase: {
                                                            position: i18n.language === 'ar' ? 'inherit !important' : 'relative',
                                                        },
                                                    },
                                                },
                                            },
                                        })
                                    }
                                >
                                    < MUIDataTable data={usersData.userDetails.sale !== null ? usersData.userDetails.sale : []} columns={Salecolumns} options={options} />
                                </ThemeProvider>
                            </TabPanel>
                            <TabPanel value="3">
                                <ThemeProvider
                                    theme={(baseTheme: Theme) =>
                                        createTheme({
                                            ...baseTheme,
                                            components: {
                                                ...baseTheme.components,
                                                MUIDataTableToolbar: {
                                                    styleOverrides: {
                                                        icon: {
                                                            color: '#000000',
                                                            '&:hover': {
                                                                // color: "#01C681"
                                                                color: "#103C64"
                                                            },
                                                            backgroundColor: "#F2F5FA",
                                                            height: '50px',
                                                            width: '50px',
                                                            borderRadius: '0px',
                                                            margin: '5px',
                                                        },
                                                        iconActive: {

                                                        }
                                                    },
                                                },
                                                MUIDataTableHeadCell: {
                                                    styleOverrides: {
                                                        fixedHeader: {
                                                            fontWeight: "600",
                                                            fontFamily: "poppins",
                                                            lineHeight: '21px',
                                                            fontSize: '14px',
                                                            color: '#ADAEB0',
                                                        }
                                                    }
                                                },
                                                MUIDataTableSelectCell: {
                                                    styleOverrides: {
                                                        fixedHeader: {
                                                            fontFamily: "poppins",
                                                            lineHeight: '15px',
                                                            color: '#333333',
                                                        }
                                                    }
                                                },
                                                MuiButton: {
                                                    styleOverrides: {
                                                        root: {
                                                            fontWeight: "600",
                                                            fontFamily: "poppins",
                                                            lineHeight: '21px',
                                                            fontSize: '14px',
                                                            color: '#ADAEB0',
                                                        },
                                                    },
                                                },
                                                MUIDataTableBodyCell: {
                                                    styleOverrides: {
                                                        root: {
                                                            fontFamily: "poppins",
                                                            lineHeight: '15px',
                                                            fontSize: '14px',
                                                            fontWeight: "400",
                                                            color: '#595F69',
                                                        }
                                                    }
                                                },
                                                MuiTooltip: {
                                                    styleOverrides: {
                                                        root: {
                                                            fontFamily: "poppins",
                                                            lineHeight: '15px',
                                                            fontSize: '14px',
                                                            fontWeight: "400",
                                                            color: 'red',
                                                        }
                                                    }
                                                },
                                                MUIDataTable: {
                                                    styleOverrides: {
                                                        responsiveBase: {
                                                            position: i18n.language === 'ar' ? 'inherit !important' : 'relative',
                                                        },
                                                    },
                                                },
                                            },
                                        })
                                    }
                                >
                                    < MUIDataTable data={usersData.userDetails.quotation !== null ? usersData.userDetails.quotation : []} columns={Quotationcolumns} options={options} />
                                </ThemeProvider>
                            </TabPanel>
                            <TabPanel value="4">
                                <ThemeProvider
                                    theme={(baseTheme: Theme) =>
                                        createTheme({
                                            ...baseTheme,
                                            components: {
                                                ...baseTheme.components,
                                                MUIDataTableToolbar: {
                                                    styleOverrides: {
                                                        icon: {
                                                            color: '#000000',
                                                            '&:hover': {
                                                                // color: "#01C681"
                                                                color: "#103C64"
                                                            },
                                                            backgroundColor: "#F2F5FA",
                                                            height: '50px',
                                                            width: '50px',
                                                            borderRadius: '0px',
                                                            margin: '5px',
                                                        },
                                                        iconActive: {

                                                        }
                                                    },
                                                },
                                                MUIDataTableHeadCell: {
                                                    styleOverrides: {
                                                        fixedHeader: {
                                                            fontWeight: "600",
                                                            fontFamily: "poppins",
                                                            lineHeight: '21px',
                                                            fontSize: '14px',
                                                            color: '#ADAEB0',
                                                        }
                                                    }
                                                },
                                                MUIDataTableSelectCell: {
                                                    styleOverrides: {
                                                        fixedHeader: {
                                                            fontFamily: "poppins",
                                                            lineHeight: '15px',
                                                            color: '#333333',
                                                        }
                                                    }
                                                },
                                                MuiButton: {
                                                    styleOverrides: {
                                                        root: {
                                                            fontWeight: "600",
                                                            fontFamily: "poppins",
                                                            lineHeight: '21px',
                                                            fontSize: '14px',
                                                            color: '#ADAEB0',
                                                        },
                                                    },
                                                },
                                                MUIDataTableBodyCell: {
                                                    styleOverrides: {
                                                        root: {
                                                            fontFamily: "poppins",
                                                            lineHeight: '15px',
                                                            fontSize: '14px',
                                                            fontWeight: "400",
                                                            color: '#595F69',
                                                        }
                                                    }
                                                },
                                                MuiTooltip: {
                                                    styleOverrides: {
                                                        root: {
                                                            fontFamily: "poppins",
                                                            lineHeight: '15px',
                                                            fontSize: '14px',
                                                            fontWeight: "400",
                                                            color: 'red',
                                                        }
                                                    }
                                                },
                                                MUIDataTable: {
                                                    styleOverrides: {
                                                        responsiveBase: {
                                                            position: i18n.language === 'ar' ? 'inherit !important' : 'relative',
                                                        },
                                                    },
                                                },
                                            },
                                        })
                                    }
                                >
                                    < MUIDataTable data={usersData.userDetails.complaint !== null ? usersData.userDetails.complaint : []} columns={Complaintcolumns} options={options} />
                                </ThemeProvider>
                            </TabPanel>
                            <TabPanel value="5">
                                <ThemeProvider
                                    theme={(baseTheme: Theme) =>
                                        createTheme({
                                            ...baseTheme,
                                            components: {
                                                ...baseTheme.components,
                                                MUIDataTableToolbar: {
                                                    styleOverrides: {
                                                        icon: {
                                                            color: '#000000',
                                                            '&:hover': {
                                                                // color: "#01C681"
                                                                color: "#103C64"
                                                            },
                                                            backgroundColor: "#F2F5FA",
                                                            height: '50px',
                                                            width: '50px',
                                                            borderRadius: '0px',
                                                            margin: '5px',
                                                        },
                                                        iconActive: {

                                                        }
                                                    },
                                                },
                                                MUIDataTableHeadCell: {
                                                    styleOverrides: {
                                                        fixedHeader: {
                                                            fontWeight: "600",
                                                            fontFamily: "poppins",
                                                            lineHeight: '21px',
                                                            fontSize: '14px',
                                                            color: '#ADAEB0',
                                                        }
                                                    }
                                                },
                                                MUIDataTableSelectCell: {
                                                    styleOverrides: {
                                                        fixedHeader: {
                                                            fontFamily: "poppins",
                                                            lineHeight: '15px',
                                                            color: '#333333',
                                                        }
                                                    }
                                                },
                                                MuiButton: {
                                                    styleOverrides: {
                                                        root: {
                                                            fontWeight: "600",
                                                            fontFamily: "poppins",
                                                            lineHeight: '21px',
                                                            fontSize: '14px',
                                                            color: '#ADAEB0',
                                                        },
                                                    },
                                                },
                                                MUIDataTableBodyCell: {
                                                    styleOverrides: {
                                                        root: {
                                                            fontFamily: "poppins",
                                                            lineHeight: '15px',
                                                            fontSize: '14px',
                                                            fontWeight: "400",
                                                            color: '#595F69',
                                                        }
                                                    }
                                                },
                                                MuiTooltip: {
                                                    styleOverrides: {
                                                        root: {
                                                            fontFamily: "poppins",
                                                            lineHeight: '15px',
                                                            fontSize: '14px',
                                                            fontWeight: "400",
                                                            color: 'red',
                                                        }
                                                    }
                                                },
                                            },
                                        })
                                    }
                                >
                                    < MUIDataTable data={usersData.userDetails.purchase !== null ? usersData.userDetails.purchase : []} columns={Purchasecolumns} options={options} />
                                </ThemeProvider>
                            </TabPanel>
                        </TabContext>
                    </Box >
                </>
            ) : (<></>)}

            <DrawerForm
                handleDrawerToggle={handleDrawerToggle}
                panelOpen={panelOpen}
                title={"Edit" + " " + location.state.UserData.role_name}
                icon="person"
                drawerWidth={900}
            >
                <CardTitle>
                    <Formlable>{t('Personal Information')}</Formlable>
                </CardTitle>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    onSubmit={async (values) => {

                        const contact_person = []
                        values.OtherContact.map((data, key) => {
                            contact_person.push(data.contact_person)
                        })

                        const contact_mobile = []
                        values.OtherContact.map((data, key) => {
                            contact_mobile.push(data.mobile)
                        })

                        const contact_designation = []
                        values.OtherContact.map((data, key) => {
                            contact_designation.push(data.designation)
                        })

                        let formData = new FormData();

                        formData.append("user_id", location.state.UserData.id);
                        formData.append("first_name", values.first_name);
                        formData.append("last_name", values.last_name);
                        formData.append("gender", values.gender);
                        if (dobvalue !== undefined) {
                            formData.append("birth_date", dobvalue);
                        } else {
                            formData.append("birth_date", values.birth_date);
                        }
                        formData.append("address", values.address);
                        formData.append("city", values.city);
                        formData.append("state", values.state);
                        formData.append("pincode", values.pincode);
                        formData.append("mobile", values.mobile);
                        formData.append("email", values.email);
                        formData.append("role", values.role);
                        if (values.password && values.password_confirmation !== "") {
                            formData.append("password", values.password);
                            formData.append("password_confirmation", values.password_confirmation);
                        }
                        if (values.profile !== null) {
                            formData.append("profile", values.profile)
                        }
                        if (location.state.UserData.role_name === "supplier") {
                            formData.append("account_number", values.account_number)
                            formData.append("bank_name", values.bank_name)
                            formData.append("branch_name", values.branch_name)
                            formData.append("swift_code", values.swift_code)
                            formData.append("branch_code", values.branch_code)
                            formData.append("international_bank_code", values.international_bank_code)
                            formData.append("national_bank_code", values.national_bank_code)
                            formData.append("gst", values.gst)

                            formData.append("company_name", values.company_name)
                            formData.append("company_contact_person", values.company_contact_person)
                            formData.append("ifsc_code", values.ifsc_code)
                            formData.append("tin_no", values.tin_no)
                            formData.append("cst_no", values.cst_no)
                            formData.append("pan_no", values.pan_no)
                            formData.append("phone", values.phone)
                        }
                        if (location.state.UserData.role_name === "customer") {
                            formData.append("account_number", values.account_number)
                            formData.append("branch_name", values.branch_name)
                            formData.append("company_name", values.company_name)
                            formData.append("company_contact_person", values.company_contact_person)
                            formData.append("ifsc_code", values.ifsc_code)
                            formData.append("tin_no", values.tin_no)
                            formData.append("cst_no", values.cst_no)
                            formData.append("pan_no", values.pan_no)
                            formData.append("phone", values.phone)

                            if (contact_person && contact_person.length > 0) {
                                for (let i = 0; i < contact_person.length; i++) {
                                    if (contact_person[i] !== "") {
                                        formData.append(`contact_person[${i}]`, contact_person[i]);
                                    }
                                }
                            }
                            if (contact_mobile && contact_mobile.length > 0) {
                                for (let i = 0; i < contact_mobile.length; i++) {
                                    if (contact_mobile[i] !== "") {
                                        formData.append(`contact_mobile[${i}]`, contact_mobile[i]);
                                    }
                                }
                            }
                            if (contact_designation && contact_designation.length > 0) {
                                for (let i = 0; i < contact_designation.length; i++) {
                                    if (contact_designation[i] !== "") {
                                        formData.append(`contact_designation[${i}]`, contact_designation[i]);
                                    }
                                }
                            }

                        }
                        values.password = ""
                        values.password_confirmation = ""
                        dispatch(updateUserDetails(formData))
                        setPanelOpen(false)
                        setImage()
                    }}
                >
                    {({ values, errors, touched, handleChange, setFieldValue, handleBlur, handleSubmit, resetForm }) => (
                        <form onSubmit={handleSubmit}>
                            <FormCard>
                                <Grid container spacing={1}>

                                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                        <TextField
                                            fullWidth
                                            type="text"
                                            name="first_name"
                                            label={t("First Name") + " *"}
                                            onBlur={handleBlur}
                                            inputProps={{
                                                className: "input_props"
                                            }}
                                            InputLabelProps={{
                                                className: "input_label_props"
                                            }}
                                            FormHelperTextProps={{
                                                className: "input_label_props"
                                            }}
                                            value={values.first_name}
                                            onChange={handleChange}
                                            helperText={touched.first_name && errors.first_name}
                                            error={Boolean(errors.first_name && touched.first_name)}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                        <TextField
                                            fullWidth
                                            type="text"
                                            name="last_name"
                                            label={t("Last Name") + " *"}
                                            inputProps={{
                                                className: "input_props"
                                            }}
                                            InputLabelProps={{
                                                className: "input_label_props"
                                            }}
                                            FormHelperTextProps={{
                                                className: "input_label_props"
                                            }}
                                            onBlur={handleBlur}
                                            value={values.last_name}
                                            onChange={handleChange}
                                            helperText={touched.last_name && errors.last_name}
                                            error={Boolean(errors.last_name && touched.last_name)}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                        <TextField
                                            fullWidth
                                            name="role"
                                            select
                                            label={t("Role")}
                                            inputProps={{
                                                readOnly: true,
                                                className: "input_props"
                                            }}
                                            InputLabelProps={{
                                                className: "input_label_props"
                                            }}
                                            FormHelperTextProps={{
                                                className: "input_label_props"
                                            }}
                                            onBlur={handleBlur}
                                            value={values.role}
                                            onChange={handleChange}
                                            helperText={touched.role && errors.role}
                                            error={Boolean(errors.role && touched.role)}
                                        >
                                            {UserRole.map((option) => (
                                                <MenuItem key={option.value} value={option.value} className="input_props">
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>

                                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                        <FormControl>
                                            <GenderLable id="demo-row-radio-buttons-group-label">{t("Gender")} *</GenderLable>
                                            <RadioGroup
                                                row
                                                name="gender"
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                onBlur={handleBlur}
                                                value={values.gender}
                                                onChange={handleChange}
                                                helperText={touched.gender && errors.gender}
                                                error={Boolean(errors.gender && touched.gender)}
                                            >
                                                <FormControlLabel value="male" control={<Radio />} label={<TypographyLable>{i18n.t("Male")}</TypographyLable>} />
                                                <FormControlLabel value="female" control={<Radio />} label={<TypographyLable>{i18n.t("Female")}</TypographyLable>} />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>

                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                            <Stack spacing={3}>
                                                <MobileDatePicker
                                                    fullWidth
                                                    label={i18n.t("Date Of Birth") + " *"}
                                                    showToolbar={false}
                                                    value={dobvalue !== undefined ? dobvalue : values.birth_date}
                                                    onChange={(newValue) => {
                                                        setDOBValue(newValue);
                                                    }}
                                                    InputLabelProps={{
                                                        className: "input_label_props"
                                                    }}
                                                    FormHelperTextProps={{
                                                        className: "input_label_props"
                                                    }}
                                                    // DialogProps={{ className: datePicker }}
                                                    InputProps={{
                                                        sx: {
                                                            "& .MuiPaper-root": {
                                                                backgroundColor: "rgba(120, 120, 120, 0.2)"
                                                            },
                                                            "& .MuiCalendarPicker-root": {
                                                                backgroundColor: "rgba(45, 85, 255, 0.4)"
                                                            },
                                                            "& .MuiPickersDay-dayWithMargin": {
                                                                color: "rgb(229,228,226)",
                                                                backgroundColor: "rgba(50, 136, 153)"
                                                            },
                                                            "& .MuiTabs-root": { backgroundColor: "rgba(120, 120, 120, 0.4)" },
                                                        }
                                                    }}
                                                    maxDate={TodayDate}
                                                    renderInput={(params) => <TextField
                                                        sx={{
                                                            input: { fontFamily: 'poppins', color: '#333333', fontWeight: '400', fontSize: '15px' },
                                                            label: { fontFamily: 'poppins', color: '#818386', fontWeight: '500', fontSize: '15px' },
                                                            '.MuiDialogActions-root': { backgroundColor: 'red' },
                                                        }} {...params} />}
                                                />
                                            </Stack>
                                        </Grid>
                                    </LocalizationProvider>

                                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                        <TextField
                                            fullWidth
                                            type="number"
                                            name="mobile"
                                            inputProps={{
                                                className: "input_props"
                                            }}
                                            InputLabelProps={{
                                                className: "input_label_props"
                                            }}
                                            FormHelperTextProps={{
                                                className: "input_label_props"
                                            }}
                                            label={t("Mobile Number") + " *"}
                                            onBlur={handleBlur}
                                            value={values.mobile}
                                            onChange={handleChange}
                                            helperText={touched.mobile && errors.mobile}
                                            error={Boolean(errors.mobile && touched.mobile)}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                        <TextField
                                            fullWidth
                                            type="email"
                                            name="email"
                                            disabled={true}
                                            inputProps={{
                                                className: "input_props"
                                            }}
                                            InputLabelProps={{
                                                className: "input_label_props"
                                            }}
                                            FormHelperTextProps={{
                                                className: "input_label_props"
                                            }}
                                            label={t("Email") + " *"}
                                            onBlur={handleBlur}
                                            value={values.email}
                                            onChange={handleChange}
                                            helperText={touched.email && errors.email}
                                            error={Boolean(errors.email && touched.email)}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                        <TextField
                                            fullWidth
                                            type="password"
                                            name="password"
                                            label={i18n.t("Password")}
                                            onBlur={handleBlur}
                                            inputProps={{
                                                className: "input_props"
                                            }}
                                            InputLabelProps={{
                                                className: "input_label_props"
                                            }}
                                            FormHelperTextProps={{
                                                className: "input_label_props"
                                            }}
                                            value={values.password}
                                            onChange={handleChange}
                                            helperText={touched.password && errors.password}
                                            error={Boolean(errors.password && touched.password)}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                        <TextField
                                            fullWidth
                                            type="password"
                                            name="password_confirmation"
                                            label={i18n.t("Confirm Password")}
                                            onBlur={handleBlur}
                                            inputProps={{
                                                className: "input_props"
                                            }}
                                            InputLabelProps={{
                                                className: "input_label_props"
                                            }}
                                            FormHelperTextProps={{
                                                className: "input_label_props"
                                            }}
                                            value={values.password_confirmation}
                                            onChange={handleChange}
                                            helperText={touched.password_confirmation && errors.password_confirmation}
                                            error={Boolean(errors.password_confirmation && touched.password_confirmation)}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                        <TextField
                                            fullWidth
                                            type="text"
                                            name="address"
                                            label={t("Address") + " *"}
                                            inputProps={{
                                                className: "input_props"
                                            }}
                                            InputLabelProps={{
                                                className: "input_label_props"
                                            }}
                                            FormHelperTextProps={{
                                                className: "input_label_props"
                                            }}
                                            onBlur={handleBlur}
                                            value={values.address}
                                            onChange={handleChange}
                                            helperText={touched.address && errors.address}
                                            error={Boolean(errors.address && touched.address)}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                        <TextField
                                            fullWidth
                                            type="text"
                                            name="city"
                                            label={t("City") + " *"}
                                            inputProps={{
                                                className: "input_props"
                                            }}
                                            InputLabelProps={{
                                                className: "input_label_props"
                                            }}
                                            FormHelperTextProps={{
                                                className: "input_label_props"
                                            }}
                                            onBlur={handleBlur}
                                            value={values.city}
                                            onChange={handleChange}
                                            helperText={touched.city && errors.city}
                                            error={Boolean(errors.city && touched.city)}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                        <TextField
                                            fullWidth
                                            type="text"
                                            name="state"
                                            label={t("State") + " *"}
                                            inputProps={{
                                                className: "input_props"
                                            }}
                                            InputLabelProps={{
                                                className: "input_label_props"
                                            }}
                                            FormHelperTextProps={{
                                                className: "input_label_props"
                                            }}
                                            onBlur={handleBlur}
                                            value={values.state}
                                            onChange={handleChange}
                                            helperText={touched.state && errors.state}
                                            error={Boolean(errors.state && touched.state)}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                        <TextField
                                            fullWidth
                                            type="number"
                                            name="pincode"
                                            label={t("Pincode") + " *"}
                                            inputProps={{
                                                className: "input_props"
                                            }}
                                            InputLabelProps={{
                                                className: "input_label_props"
                                            }}
                                            FormHelperTextProps={{
                                                className: "input_label_props"
                                            }}
                                            onBlur={handleBlur}
                                            value={values.pincode}
                                            onChange={handleChange}
                                            helperText={touched.pincode && errors.pincode}
                                            error={Boolean(errors.pincode && touched.pincode)}
                                        />
                                    </Grid>

                                </Grid>
                            </FormCard>

                            <>
                                {location.state.UserData && location.state.UserData.role_name === "customer" || location.state.UserData && location.state.UserData.role_name === "supplier" ? (
                                    <>
                                        <CardTitle>
                                            <Formlable>{i18n.t("Company Information")}</Formlable>
                                        </CardTitle>

                                        <FormCard>
                                            <Grid container spacing={1}>

                                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                    <TextField
                                                        fullWidth
                                                        type="text"
                                                        name="company_name"
                                                        label={t("Company Name") + " *"}
                                                        inputProps={{
                                                            className: "input_props"
                                                        }}
                                                        InputLabelProps={{
                                                            className: "input_label_props"
                                                        }}
                                                        FormHelperTextProps={{
                                                            className: "input_label_props"
                                                        }}
                                                        onBlur={handleBlur}
                                                        value={values.company_name}
                                                        onChange={handleChange}
                                                        helperText={touched.company_name && errors.company_name}
                                                        error={Boolean(errors.company_name && touched.company_name)}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                    <TextField
                                                        fullWidth
                                                        name="company_contact_person"
                                                        label={t("Company Contact Person") + " *"}
                                                        inputProps={{
                                                            className: "input_props"
                                                        }}
                                                        InputLabelProps={{
                                                            className: "input_label_props"
                                                        }}
                                                        FormHelperTextProps={{
                                                            className: "input_label_props"
                                                        }}
                                                        onBlur={handleBlur}
                                                        value={values.company_contact_person}
                                                        onChange={handleChange}
                                                        helperText={touched.company_contact_person && errors.company_contact_person}
                                                        error={Boolean(errors.company_contact_person && touched.company_contact_person)}
                                                    />
                                                </Grid>

                                            </Grid>
                                        </FormCard>
                                    </>) : (<></>)}
                            </>


                            <>
                                {location.state.UserData && location.state.UserData.role_name === "customer" || location.state.UserData && location.state.UserData.role_name === "supplier" ? (
                                    <>
                                        {/* Company details accordion */}

                                        <AddSecondaccordion>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography>
                                                        <Formlable>
                                                            {t("Company Information")}
                                                        </Formlable>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ padding: "0px" }}>
                                                    <Typography>

                                                        <FormCard>
                                                            <Grid container spacing={1}>

                                                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                                    <TextField
                                                                        fullWidth
                                                                        type="number"
                                                                        name="account_number"
                                                                        label={t("Account Number")}
                                                                        inputProps={{
                                                                            className: "input_props"
                                                                        }}
                                                                        InputLabelProps={{
                                                                            className: "input_label_props"
                                                                        }}
                                                                        FormHelperTextProps={{
                                                                            className: "input_label_props"
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        value={values.account_number}
                                                                        onChange={handleChange}
                                                                        helperText={touched.account_number && errors.account_number}
                                                                        error={Boolean(errors.account_number && touched.account_number)}
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                                    <TextField
                                                                        fullWidth
                                                                        type="text"
                                                                        name="ifsc_code"
                                                                        label={t("IFSC Code")}
                                                                        inputProps={{
                                                                            className: "input_props"
                                                                        }}
                                                                        InputLabelProps={{
                                                                            className: "input_label_props"
                                                                        }}
                                                                        FormHelperTextProps={{
                                                                            className: "input_label_props"
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        value={values.ifsc_code}
                                                                        onChange={handleChange}
                                                                        helperText={touched.ifsc_code && errors.ifsc_code}
                                                                        error={Boolean(errors.ifsc_code && touched.ifsc_code)}
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                                    <TextField
                                                                        fullWidth
                                                                        type="text"
                                                                        name="branch_name"
                                                                        label={t("Branch Name")}
                                                                        inputProps={{
                                                                            className: "input_props"
                                                                        }}
                                                                        InputLabelProps={{
                                                                            className: "input_label_props"
                                                                        }}
                                                                        FormHelperTextProps={{
                                                                            className: "input_label_props"
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        value={values.branch_name}
                                                                        onChange={handleChange}
                                                                        helperText={touched.branch_name && errors.branch_name}
                                                                        error={Boolean(errors.branch_name && touched.branch_name)}
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                                    <TextField
                                                                        fullWidth
                                                                        type="number"
                                                                        name="tin_no"
                                                                        label={t("TIN Number")}
                                                                        inputProps={{
                                                                            className: "input_props"
                                                                        }}
                                                                        InputLabelProps={{
                                                                            className: "input_label_props"
                                                                        }}
                                                                        FormHelperTextProps={{
                                                                            className: "input_label_props"
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        value={values.tin_no}
                                                                        onChange={handleChange}
                                                                        helperText={touched.tin_no && errors.tin_no}
                                                                        error={Boolean(errors.tin_no && touched.tin_no)}
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                                    <TextField
                                                                        fullWidth
                                                                        type="number"
                                                                        name="cst_no"
                                                                        label={t("CST Number")}
                                                                        inputProps={{
                                                                            className: "input_props"
                                                                        }}
                                                                        InputLabelProps={{
                                                                            className: "input_label_props"
                                                                        }}
                                                                        FormHelperTextProps={{
                                                                            className: "input_label_props"
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        value={values.cst_no}
                                                                        onChange={handleChange}
                                                                        helperText={touched.cst_no && errors.cst_no}
                                                                        error={Boolean(errors.cst_no && touched.cst_no)}
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                                    <TextField
                                                                        fullWidth
                                                                        type="text"
                                                                        name="pan_no"
                                                                        label={t("PAN Number")}
                                                                        inputProps={{
                                                                            className: "input_props"
                                                                        }}
                                                                        InputLabelProps={{
                                                                            className: "input_label_props"
                                                                        }}
                                                                        FormHelperTextProps={{
                                                                            className: "input_label_props"
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        value={values.pan_no}
                                                                        onChange={handleChange}
                                                                        helperText={touched.pan_no && errors.pan_no}
                                                                        error={Boolean(errors.pan_no && touched.pan_no)}
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                    <TextField
                                                                        fullWidth
                                                                        type="number"
                                                                        name="phone"
                                                                        label={t("Phone Number")}
                                                                        inputProps={{
                                                                            className: "input_props"
                                                                        }}
                                                                        InputLabelProps={{
                                                                            className: "input_label_props"
                                                                        }}
                                                                        FormHelperTextProps={{
                                                                            className: "input_label_props"
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        value={values.phone}
                                                                        onChange={handleChange}
                                                                        helperText={touched.phone && errors.phone}
                                                                        error={Boolean(errors.phone && touched.phone)}
                                                                    />
                                                                </Grid>

                                                            </Grid>
                                                        </FormCard>

                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        </AddSecondaccordion>

                                        <>
                                            {location.state.UserData && location.state.UserData.role_name === "customer" ? (
                                                <>

                                                    {/* customer other contact Information */}

                                                    <AddSecondaccordion>
                                                        <Accordion>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls="panel1a-content"
                                                                id="panel1a-header"
                                                            >
                                                                <Typography>
                                                                    <Formlable>
                                                                        {t("Other Contact Information")}
                                                                    </Formlable>
                                                                </Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails style={{ padding: "0px" }}>
                                                                <Typography>
                                                                    <FieldArray name="OtherContact">
                                                                        {({ push, remove }) => (
                                                                            <React.Fragment>
                                                                                {values.OtherContact && values.OtherContact.map((id, index) => (
                                                                                    <FormCard className="form-inline" key={index}>
                                                                                        <Grid container spacing={1}>

                                                                                            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                                                                                <TextField
                                                                                                    fullWidth
                                                                                                    type="text"
                                                                                                    name={`OtherContact.${index}.contact_person`}
                                                                                                    value={values.OtherContact[index].contact_person}
                                                                                                    label={t("Contact Person")}
                                                                                                    inputProps={{
                                                                                                        className: "input_props"
                                                                                                    }}
                                                                                                    InputLabelProps={{
                                                                                                        className: "input_label_props"
                                                                                                    }}
                                                                                                    FormHelperTextProps={{
                                                                                                        className: "input_label_props"
                                                                                                    }}
                                                                                                    onBlur={handleBlur}
                                                                                                    onChange={handleChange}
                                                                                                />
                                                                                            </Grid>

                                                                                            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                                                                                <TextField
                                                                                                    fullWidth
                                                                                                    type="number"
                                                                                                    name={`OtherContact.${index}.mobile`}
                                                                                                    value={values.OtherContact[index].mobile}
                                                                                                    label={t("Mobile Number")}
                                                                                                    inputProps={{
                                                                                                        className: "input_props"
                                                                                                    }}
                                                                                                    InputLabelProps={{
                                                                                                        className: "input_label_props"
                                                                                                    }}
                                                                                                    FormHelperTextProps={{
                                                                                                        className: "input_label_props"
                                                                                                    }}
                                                                                                    onBlur={handleBlur}
                                                                                                    onChange={handleChange}
                                                                                                />
                                                                                            </Grid>

                                                                                            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                                                                                <TextField
                                                                                                    fullWidth
                                                                                                    type="text"
                                                                                                    name={`OtherContact.${index}.designation`}
                                                                                                    value={values.OtherContact[index].designation}
                                                                                                    label={t("Designation")}
                                                                                                    inputProps={{
                                                                                                        className: "input_props"
                                                                                                    }}
                                                                                                    InputLabelProps={{
                                                                                                        className: "input_label_props"
                                                                                                    }}
                                                                                                    FormHelperTextProps={{
                                                                                                        className: "input_label_props"
                                                                                                    }}
                                                                                                    onBlur={handleBlur}
                                                                                                    onChange={handleChange}
                                                                                                />
                                                                                            </Grid>

                                                                                            <Button className="button add" type="button" onClick={() => push(Empty)}>
                                                                                                <AddCircleSharpIcon />
                                                                                            </Button>
                                                                                            <Button type="button" className="button remove" disabled={values.OtherContact.length > 1 ? false : true} onClick={() => remove(index)}>
                                                                                                <RemoveCircleSharpIcon />
                                                                                            </Button>
                                                                                        </Grid>
                                                                                    </FormCard>
                                                                                ))}
                                                                            </React.Fragment>
                                                                        )}
                                                                    </FieldArray>
                                                                </Typography>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </AddSecondaccordion>

                                                </>
                                            ) : (<></>)}
                                        </>

                                    </>
                                ) : (<></>)}
                            </>

                            <>
                                {location.state.UserData && location.state.UserData.role_name === "supplier" ? (
                                    <>
                                        {/* Account details accordion */}

                                        <AddSecondaccordion>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography>
                                                        <Formlable>
                                                            {t("Account Information")}
                                                        </Formlable>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ padding: "0px" }}>
                                                    <Typography>

                                                        <FormCard>
                                                            <Grid container spacing={1}>

                                                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                                    <TextField
                                                                        fullWidth
                                                                        type="number"
                                                                        name="account_number"
                                                                        label={t("Account Number")}
                                                                        inputProps={{
                                                                            className: "input_props"
                                                                        }}
                                                                        InputLabelProps={{
                                                                            className: "input_label_props"
                                                                        }}
                                                                        FormHelperTextProps={{
                                                                            className: "input_label_props"
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        value={values.account_number}
                                                                        onChange={handleChange}
                                                                        helperText={touched.account_number && errors.account_number}
                                                                        error={Boolean(errors.account_number && touched.account_number)}
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                                    <TextField
                                                                        fullWidth
                                                                        type="text"
                                                                        name="bank_name"
                                                                        label={t("Bank Name")}
                                                                        inputProps={{
                                                                            className: "input_props"
                                                                        }}
                                                                        InputLabelProps={{
                                                                            className: "input_label_props"
                                                                        }}
                                                                        FormHelperTextProps={{
                                                                            className: "input_label_props"
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        value={values.bank_name}
                                                                        onChange={handleChange}
                                                                        helperText={touched.bank_name && errors.bank_name}
                                                                        error={Boolean(errors.bank_name && touched.bank_name)}
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                                    <TextField
                                                                        fullWidth
                                                                        type="text"
                                                                        name="branch_name"
                                                                        label={t("Branch Name")}
                                                                        inputProps={{
                                                                            className: "input_props"
                                                                        }}
                                                                        InputLabelProps={{
                                                                            className: "input_label_props"
                                                                        }}
                                                                        FormHelperTextProps={{
                                                                            className: "input_label_props"
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        value={values.branch_name}
                                                                        onChange={handleChange}
                                                                        helperText={touched.branch_name && errors.branch_name}
                                                                        error={Boolean(errors.branch_name && touched.branch_name)}
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                                    <TextField
                                                                        fullWidth
                                                                        type="number"
                                                                        name="swift_code"
                                                                        label={t("Switch Code")}
                                                                        inputProps={{
                                                                            className: "input_props"
                                                                        }}
                                                                        InputLabelProps={{
                                                                            className: "input_label_props"
                                                                        }}
                                                                        FormHelperTextProps={{
                                                                            className: "input_label_props"
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        value={values.swift_code}
                                                                        onChange={handleChange}
                                                                        helperText={touched.swift_code && errors.swift_code}
                                                                        error={Boolean(errors.swift_code && touched.swift_code)}
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                                    <TextField
                                                                        fullWidth
                                                                        type="number"
                                                                        name="branch_code"
                                                                        label={t("Branch Code")}
                                                                        inputProps={{
                                                                            className: "input_props"
                                                                        }}
                                                                        InputLabelProps={{
                                                                            className: "input_label_props"
                                                                        }}
                                                                        FormHelperTextProps={{
                                                                            className: "input_label_props"
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        value={values.branch_code}
                                                                        onChange={handleChange}
                                                                        helperText={touched.branch_code && errors.branch_code}
                                                                        error={Boolean(errors.branch_code && touched.branch_code)}
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                                    <TextField
                                                                        fullWidth
                                                                        type="number"
                                                                        name="international_bank_code"
                                                                        label={t("International Bank Code")}
                                                                        inputProps={{
                                                                            className: "input_props"
                                                                        }}
                                                                        InputLabelProps={{
                                                                            className: "input_label_props"
                                                                        }}
                                                                        FormHelperTextProps={{
                                                                            className: "input_label_props"
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        value={values.international_bank_code}
                                                                        onChange={handleChange}
                                                                        helperText={touched.international_bank_code && errors.international_bank_code}
                                                                        error={Boolean(errors.international_bank_code && touched.international_bank_code)}
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                                    <TextField
                                                                        fullWidth
                                                                        type="number"
                                                                        name="national_bank_code"
                                                                        label={t("National Bank Code")}
                                                                        inputProps={{
                                                                            className: "input_props"
                                                                        }}
                                                                        InputLabelProps={{
                                                                            className: "input_label_props"
                                                                        }}
                                                                        FormHelperTextProps={{
                                                                            className: "input_label_props"
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        value={values.national_bank_code}
                                                                        onChange={handleChange}
                                                                        helperText={touched.national_bank_code && errors.national_bank_code}
                                                                        error={Boolean(errors.national_bank_code && touched.national_bank_code)}
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                                    <TextField
                                                                        fullWidth
                                                                        name="gst"
                                                                        label={t("GST")}
                                                                        inputProps={{
                                                                            className: "input_props"
                                                                        }}
                                                                        InputLabelProps={{
                                                                            className: "input_label_props"
                                                                        }}
                                                                        FormHelperTextProps={{
                                                                            className: "input_label_props"
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        value={values.gst}
                                                                        onChange={handleChange}
                                                                        helperText={touched.gst && errors.gst}
                                                                        error={Boolean(errors.gst && touched.gst)}
                                                                    />
                                                                </Grid>

                                                            </Grid>
                                                        </FormCard>

                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        </AddSecondaccordion>

                                    </>
                                ) : (<></>)}
                            </>

                            {/* other Information accordion */}

                            <AddSecondaccordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>
                                            <Formlable>
                                                {t("Other Information")}
                                            </Formlable>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails style={{ padding: "0px" }}>
                                        <Typography>

                                            <FormCard>
                                                <Grid container spacing={1}>

                                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                        <TextField
                                                            fullWidth
                                                            type="file"
                                                            name="profile"
                                                            label={t("Upload Image")}
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                                className: "input_props"
                                                            }}
                                                            InputLabelProps={{
                                                                className: "input_label_props"
                                                            }}
                                                            FormHelperTextProps={{
                                                                className: "input_label_props"
                                                            }}
                                                            onBlur={handleBlur}
                                                            // onChange={(e) => handleProfileChange(e)}
                                                            onChange={(e) => {
                                                                setFieldValue('profile', e.target.files[0]);
                                                                setImage(URL.createObjectURL(e.target.files[0]))
                                                            }}
                                                            helperText={touched.profile && errors.profile}
                                                            error={Boolean(errors.profile && touched.profile)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={5} sm={5} md={4} lg={4} xl={4}>
                                                        {image === undefined ?
                                                            (
                                                                <img src={location.state.UserData && location.state.UserData.profile} alt="" height="100px" width="100px" />
                                                            ) : (
                                                                <img src={image} alt="" height="100px" width="100px" />
                                                            )}
                                                    </Grid>
                                                </Grid>

                                            </FormCard>

                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </AddSecondaccordion>

                            <SubmitButton>
                                <Grid item xs={12}>
                                    <BootstrapButton color="primary" variant="contained" type="submit">
                                        <Icon>send</Icon>
                                        <Span sx={{ pl: 1, textTransform: 'capitalize' }}>{t("Edit User")}</Span>
                                    </BootstrapButton>
                                </Grid>
                            </SubmitButton>

                        </form>
                    )}
                </Formik>

            </DrawerForm >

        </Container >
    )
}
export default Viewuser;