import { Box, Snackbar } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import { clearSnackbar } from "../redux/actions/UiActions";
import Alert from "@mui/material/Alert";

export default function Toast() {

  const dispatch = useDispatch()
  const { snackbarMessage, snackbarOpen, variant } = useSelector((state) => state.ui)

  function handleClose() {
    dispatch(clearSnackbar());
  }

  return (
    <Box>
      <Snackbar
        open={snackbarOpen}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={4000}
        transitionDuration={{ enter: 1000, exit: 2000 }}
        TransitionProps={{ enter: true, exit: false }}
        onClose={handleClose}
      >
        <Alert variant="filled" onClose={handleClose} severity={variant === "success" ? "success" : "error"} sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
