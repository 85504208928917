import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const Complaint = Loadable(lazy(() => import('./index.js')));

const ComplaintRoutes = [
  { path: '/complaint/list', element: <Complaint />, auth: authRoles.admin },
];

export default ComplaintRoutes;
