import React, { createContext, useEffect, useReducer } from 'react';
import jwtDecode from 'jwt-decode';
import axios from 'axios.js';
import { MatxLoading } from 'app/components';
import useSnackbar from 'app/hooks/useSnackbar';

const initialState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
};

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decodedToken = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp > currentTime;
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT': {
      const { isAuthenticated, user } = action.payload;
      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => { },
  register: () => Promise.resolve(),
  forgotPassword: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [msg, sendNotification] = useSnackbar();
  const login = async (email, password) => {
    const response = await axios.post('/login', {
      email,
      password,
    });

    if (response.data.error) {
      sendNotification({ msg: response.data.message, variant: 'error' });
      return Promise.reject((response && response.data) || 'Something went wrong!');
    }
    const { accessToken, user } = response.data;
    window.localStorage.setItem('UserRole', user.role_name);
    if (user.role_name !== 'admin') {
      const result = response.data.permissions.map((item) => {
        return {
          name: item.name,
        };
      });
      window.localStorage.setItem('Permission', JSON.stringify(result));
    }

    setSession(accessToken);

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };

  const forgotPassword = async (email) => {
    const response = await axios.post('/password/email', {
      email,
    });

    if (response.data.error) {
      sendNotification({ msg: response.data.message, variant: 'error' });
      return Promise.reject((response && response.data) || 'Something went wrong!');
    }

    if (!response.data.error) {
      sendNotification({ msg: response.data.message, variant: 'success' });
      return Promise.resolve();
    }
  };

  const resetPassword = async (email, token, password, password_confirmation) => {
    const response = await axios.post('password/reset', {
      email,
      token,
      password,
      password_confirmation,
    });

    if (response.data.error) {
      sendNotification({ msg: response.data.message, variant: 'error' });
      return Promise.reject((response && response.data) || 'Something went wrong!');
    }

    if (!response.data.error) {
      sendNotification({ msg: response.data.message, variant: 'success' });
      return Promise.resolve();
    }
  };

  const register = async (email, username, password) => {
    const response = await axios.post('/api/auth/register', {
      email,
      username,
      password,
    });

    const { accessToken, user } = response.data;

    setSession(accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
    window.localStorage.removeItem('Permission');
    window.localStorage.removeItem('UserRole');
  };
  useEffect(() => {
    (async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          const response = await axios.get('user/profile');
          const { data } = response.data;
          dispatch({
            type: 'INIT',
            payload: {
              isAuthenticated: true,
              user: data,
            },
          });
        } else {
          dispatch({
            type: 'INIT',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INIT',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    })();
  }, []);

  if (!state.isInitialised) {
    return <MatxLoading />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register,
        forgotPassword,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
