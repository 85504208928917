import AuthGuard from 'app/auth/AuthGuard';
import userRoute from 'app/views/Users/UserRoutes';
import productRoutes from 'app/views/Product/ProductRoutes';
import AmcRoutes from 'app/views/amc/AmcRoutes';
import PurchaseRoutes from 'app/views/purchase/PurchaseRoutes';
import serviceRoutes from 'app/views/service/serviceRoutes';
import stockRoutes from 'app/views/stock/StockRoutes';
import quotationRoutes from 'app/views/quotation/QuotationRoutes';
import taxRoutes from 'app/views/tax/TaxRoutes';
import salesRoutes from 'app/views/sales/SalesRoutes';
import complaintRoutes from 'app/views/complaint/ComplaintRoutes';
import storeserviceRoutes from 'app/views/storeservice/storeserviceRoutes';
import taskRoutes from 'app/views/task/TaskRoutes';
import incomeRoutes from 'app/views/income/IncomeRoutes';
import expenseRoutes from 'app/views/expense/ExpenseRoutes';
import reportRoutes from 'app/views/report/ReportRoutes';
import emailTemplateRoutes from 'app/views/emailtemplate/EmailTemplateRoutes';
import settingRoutes from 'app/views/setting/SettingRoutes';
import accessrightsRoutes from 'app/views/accessrights/AccessrightsRoutes';
import chartsRoute from 'app/views/charts/ChartsRoute';
import dashboardRoutes from 'app/views/dashboard/DashboardRoutes';
import materialRoutes from 'app/views/material-kit/MaterialRoutes';
import NotFound from 'app/views/sessions/NotFound';
import UsersView from 'app/views/Users/Viewuser';
import sessionRoutes from 'app/views/sessions/SessionRoutes';
import { Navigate } from 'react-router-dom';
import MatxLayout from './components/MatxLayout/MatxLayout';

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [...dashboardRoutes, ...chartsRoute, ...materialRoutes, ...userRoute, ...productRoutes, ...AmcRoutes, ...PurchaseRoutes, ...serviceRoutes, ...stockRoutes, ...quotationRoutes, ...taxRoutes, ...salesRoutes, ...complaintRoutes, ...storeserviceRoutes, ...taskRoutes, ...incomeRoutes, ...expenseRoutes, ...reportRoutes, ...emailTemplateRoutes, ...settingRoutes, ...accessrightsRoutes, { path: '/users/list/viewuser', element: <UsersView /> }],
  },
  ...sessionRoutes,
  { path: '/', element: <Navigate to="dashboard/default" /> },
  { path: '*', element: <NotFound /> },
];

export default routes;
