import axios from 'axios.js';
import { showSnackbar } from './UiActions';

export const GET_SERVICE_LIST = 'GET_SERVICE_LIST';
export const ADD_SERVICES = 'ADD_SERVICES';
export const SERVICE_USER = 'SERVICE_USER';
export const MULTIPLE_SERVICE_DELETE = 'MULTIPLE_SERVICE_DELETE';
export const UPDATE_SERVICES = 'UPDATE_SERVICES';

export const getserviceList = () => (dispatch) => {
  axios.get('/service/list').then((res) => {
    dispatch({
      type: GET_SERVICE_LIST,
      payload: res.data.data,
    });
  });
};

// export const addServices = (data) => (dispatch) => {
//   axios
//     .post('/service/store', data)
//     .then((response) => {
//       dispatch(showSnackbar(response.data.message, 'success'));
//       dispatch({
//         type: ADD_SERVICES,
//         payload: response.data,
//       });
//       dispatch(getserviceList());
//     })
//     .catch((error) => {
//       dispatch(showSnackbar(error.message, 'error'));
//     });
// };

export const addServices = (data) => async (dispatch) => {
  try {
    const response = await axios.post("/service/store", data);
    dispatch(showSnackbar(response.data.message, 'success'));
    dispatch({
      type: ADD_SERVICES,
      payload: response.data,
    });
    if (response.status === 200) {
      dispatch(getserviceList());
    }

    return response;
  } catch (error) {
    dispatch(
      showSnackbar(error.response?.data?.message || error.message, "error")
    );
    return error.response?.data?.message || error.message;
  }
};

export const updateServices = (data) => (dispatch) => {
  axios
    .post('/service/update', data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch({
        type: UPDATE_SERVICES,
        payload: response.data,
      });
      dispatch(getserviceList());
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
    });
};

export const deleteService = (data) => (dispatch) => {
  axios
    .post('/service/delete', data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch({
        type: SERVICE_USER,
        payload: response.data,
      });
      dispatch(getserviceList());
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
    });
};

export const multipleServiceDelete = (data) => (dispatch) => {
  axios
    .post('/service/deleteMultiService', data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch({
        type: MULTIPLE_SERVICE_DELETE,
        payload: response.data,
      });
      dispatch(getserviceList());
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
    });
};

export const approveServiceStatus = (data) => (dispatch) => {
  axios
    .post('/service/approveEmployeeStatus', data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch(getserviceList());
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
      return Promise.reject();
    });
};

export const rejectServiceStatus = (data) => (dispatch) => {
  axios
    .post('/service/rejectEmployeeStatus', data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch(getserviceList());
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
      return Promise.reject();
    });
};
