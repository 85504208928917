import axios from "axios.js";
import {
  getCustomerDropdown,
  getEmployeeDropdown,
  getSupplierDropdown,
} from "./CategoryActions";
import { showSnackbar } from "./UiActions";

export const GET_USERS_LIST = "GET_USERS_LIST";
export const ADD_USERS = "ADD_USERS";
export const DELETE_USER = "DELETE_USER";
export const MULTIPLE_USER_DELETE = "MULTIPLE_USER_DELETE";
export const UPDATE_USERS_DETAILS = "UPDATE_USERS_DETAILS";
export const GET_USERS_DETAILS = "GET_USERS_DETAILS";
export const UPDATE_PROFILE = "UPDATE_PROFILE";

export const getUsersList = () => (dispatch) => {
  axios.get("/user/list").then((res) => {
    dispatch({
      type: GET_USERS_LIST,
      payload: res.data,
    });
  });
};

export const addUsers = (data) => async (dispatch) => {
  try {
    const response = await axios.post("/user/store", data);
    dispatch(showSnackbar(response.data.message, "success"));
    dispatch({
      type: ADD_USERS,
      payload: response.data,
    });
    if (response.status === 200) {
      dispatch(getUsersList());
      dispatch(getCustomerDropdown({ role: "customer" }));
      dispatch(getEmployeeDropdown({ role: "employee" }));
      dispatch(getSupplierDropdown({ role: "supplier" }));
    }

    return response;
  } catch (error) {
    dispatch(
      showSnackbar(error.response?.data?.message || error.message, "error")
    );
    return error.response?.data?.message || error.message;
  }
};

export const deleteUser = (data) => (dispatch) => {
  axios
    .post("/user/delete", data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, "success"));
      dispatch({
        type: DELETE_USER,
        payload: response.data,
      });
      dispatch(getUsersList());
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, "error"));
      // if (error.message === 'Unauthorized') {
      //   window.location.reload();
      // }
    });
};

export const multipleUserDelete = (data) => (dispatch) => {
  axios
    .post("/user/deleteMultiUser", data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, "success"));
      dispatch({
        type: MULTIPLE_USER_DELETE,
        payload: response.data,
      });
      dispatch(getUsersList());
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, "error"));
    });
};

export const updateUserDetails = (data) => (dispatch) => {
  axios
    .post("/user/updateOtherUser", data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, "success"));
      dispatch({
        type: UPDATE_USERS_DETAILS,
        payload: response.data,
      });
      if (window.localStorage.getItem("UserRole") !== "admin") {
        localStorage.setItem("UserName", response.data.first_name);
        localStorage.setItem("UserImage", response.data.profile);
        window.location.reload();
      }
      window.location.reload();
      dispatch(getUsersList());
      dispatch(getUsersDetails({ email: response.data.data.email }));
      dispatch(getCustomerDropdown({ role: "customer" }));
      dispatch(getEmployeeDropdown({ role: "employee" }));
      dispatch(getSupplierDropdown({ role: "supplier" }));
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, "error"));
    });
};

export const getUsersDetails = (data) => (dispatch) => {
  axios
    .post("/user/fullprofile", data)
    .then((response) => {
      dispatch({
        type: GET_USERS_DETAILS,
        payload: response.data.data,
      });
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, "error"));
    });
};

export const updateProfile = (data) => (dispatch) => {
  axios
    .post("/user/update", data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, "success"));
      dispatch({
        type: UPDATE_PROFILE,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, "error"));
    });
};
