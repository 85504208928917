import {
  GET_USERS_LIST,
  ADD_USERS,
  DELETE_USER,
  MULTIPLE_USER_DELETE,
  UPDATE_USERS_DETAILS,
  GET_USERS_DETAILS,
} from '../actions/UsersActions';

const initialState = {
  userList: [],
  Data: '',
  updateData: '',
  userDetails: [],
};

const UsersReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_USERS_LIST: {
      return { ...state, userList: action.payload };
    }
    case ADD_USERS: {
      return { ...state, Data: action.payload };
    }
    case DELETE_USER: {
      return { ...state };
    }
    case MULTIPLE_USER_DELETE: {
      return { ...state };
    }
    case UPDATE_USERS_DETAILS: {
      return { ...state, updateData: action.payload };
    }
    case GET_USERS_DETAILS: {
      return { ...state, userDetails: action.payload };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default UsersReducer;
