import axios from 'axios.js';
import { getCategoryList } from './CategoryActions';
import { showSnackbar } from './UiActions';

export const GET_TASK_LIST = 'GET_TASK_LIST';
export const ADD_TASK = 'ADD_TASK';
export const DELETE_TASK = 'DELETE_TASK';
export const UPDATE_TASK = 'UPDATE_TASK';
export const MULTIPLE_DELETE_TASK = 'MULTIPLE_DELETE_TASK';
export const ADD_TASK_TYPE = 'ADD_TASK_TYPE';
export const DELETE_TASK_TYPE = 'DELETE_TASK_TYPE';

export const getTaskList = () => (dispatch) => {
  axios.get('/task/list').then((res) => {
    dispatch({
      type: GET_TASK_LIST,
      payload: res.data.data,
    });
  });
};

export const addTask = (data) => (dispatch) => {
  axios
    .post('/task/store', data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch({
        type: ADD_TASK,
        payload: response.data.data,
      });
      dispatch(getTaskList());
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
      return Promise.reject();
    });
};

export const updateTask = (data) => (dispatch) => {
  axios
    .post('/task/update', data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch({
        type: UPDATE_TASK,
        payload: response.data.data,
      });
      dispatch(getTaskList());
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
      return Promise.reject();
    });
};

export const deleteTask = (data) => (dispatch) => {
  axios
    .post('/task/delete', data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch({
        type: DELETE_TASK,
        payload: response.data.data,
      });
      dispatch(getTaskList());
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
      return Promise.reject();
    });
};

export const multipleDeleteTask = (data) => (dispatch) => {
  axios
    .post('/task/deleteMulti', data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch({
        type: MULTIPLE_DELETE_TASK,
        payload: response.data.data,
      });
      dispatch(getTaskList());
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
      return Promise.reject();
    });
};

export const addTaskType = (data) => (dispatch) => {
  axios.post('/category/store', data).then((response) => {
    dispatch(showSnackbar(response.data.message, 'success'));
    dispatch({
      type: ADD_TASK_TYPE,
      payload: response.data,
    });
    dispatch(getCategoryList());
  })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
    });
};

export const reomveTaskType = (data) => (dispatch) => {
  axios.post('/category/delete', data).then((response) => {
    dispatch(showSnackbar(response.data.message, 'success'));
    dispatch({
      type: DELETE_TASK_TYPE,
      payload: response.data,
    });
    dispatch(getCategoryList());
  })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
    });
};

export const approveEmployeeStatus = (data) => (dispatch) => {
  axios
    .post('/task/approveEmployeeStatus', data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch(getTaskList());
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
      return Promise.reject();
    });
};

export const rejectEmployeeStatus = (data) => (dispatch) => {
  axios
    .post('/task/rejectEmployeeStatus', data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch(getTaskList());
      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
      return Promise.reject();
    });
};