import axios from 'axios';
import { env } from './env'

const axiosInstance = axios.create({
  // baseURL: 'http://192.168.1.131/amc_api_laravel/public/api',
  // baseURL: 'http://api.amc.niftysol.com/api',
  // baseURL: 'http://amc_api.test:82/api',
  // baseURL: 'http://192.168.1.11:82/amc_api/public/api',
  baseURL: env.REACT_APP_BASE_URL,
});

// axiosInstance.interceptors.response.use(
//   (response) => response,
//   (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong!')
// );

// axiosInstance.interceptors.response.use(
//   (response) => response,
//   (error) =>
//     Promise.reject(
//       error.response && error.response.data.error_code === 401
//         ? (window.location = '/session/signin')
//         : 'Something went wrong!'
//     )
// );

export default axiosInstance;
