import axios from 'axios.js';
import { showSnackbar } from './UiActions';

export const GET_SALES_LIST = 'GET_SALES_LIST';
export const ADD_SALES = 'ADD_SALES';
export const UPDATE_SALES = 'UPDATE_SALES';
export const DELETE_SALES = 'DELETE_SALES';
export const MULTIPLE_DELETE_SALES = 'MULTIPLE_DELETE_SALES';
export const LOADING_START_SELL = 'LOADING_START_SELL';
export const LOADING_END_SELL = 'LOADING_END_SELL';

export const getsalesList = () => (dispatch) => {
  axios.get('/sale/list').then((res) => {
    dispatch({
      type: GET_SALES_LIST,
      payload: res.data.data,
    });
  });
};

export const addSales = (data) => (dispatch) => {
  axios
    .post('/sale/store', data)
    .then((response) => {
      dispatch({
        type: ADD_SALES,
        payload: response.data,
      });
      dispatch(getsalesList());
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch(loadingEnd());
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
    });
};

export const updateSales = (data) => (dispatch) => {
  axios
    .post('/sale/update', data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch({
        type: UPDATE_SALES,
        payload: response.data,
      });
      dispatch(getsalesList());
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
    });
};

export const deleteSales = (data) => (dispatch) => {
  axios
    .post('/sale/delete', data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch({
        type: DELETE_SALES,
        payload: response.data,
      });
      dispatch(getsalesList());
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
    });
};

export const multipledeleteSales = (data) => (dispatch) => {
  axios
    .post('/sale/deleteMulti', data)
    .then((response) => {
      dispatch(showSnackbar(response.data.message, 'success'));
      dispatch({
        type: MULTIPLE_DELETE_SALES,
        payload: response.data,
      });
      dispatch(getsalesList());
    })
    .catch((error) => {
      dispatch(showSnackbar(error.message, 'error'));
    });
};

export const loadingStart = () => (dispatch) => {
  dispatch({
    type: LOADING_START_SELL,
  });
};

export const loadingEnd = () => (dispatch) => {
  dispatch({
    type: LOADING_END_SELL,
  });
};
