import React, { Fragment, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// Destructuring props
const Thired = ({
  handleBack,
  handleNext,
  handleChange,
  values: { email, password, confirm_password },
  formErrors,
}) => {
  // Check if all values are not empty or if there are some error
  const isValid =
    email.length > 0 &&
    !formErrors.email &&
    password.length > 0 &&
    !formErrors.password &&
    confirm_password.length > 0 &&
    !formErrors.confirm_password;

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const [showSecondPassword, setShowSecondPassword] = useState(false);
  const handleClickShowSecondPassword = () => setShowSecondPassword(!showSecondPassword);

  return (
    <Fragment>
      <Grid container spacing={2} noValidate>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            variant="outlined"
            label="Email"
            name="email"
            margin="normal"
            value={email || ''}
            onChange={handleChange}
            error={!!formErrors.email}
            helperText={formErrors.email}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            variant="outlined"
            label="Password"
            name="password"
            margin="normal"
            type={showPassword ? 'text' : 'password'}
            value={password || ''}
            onChange={handleChange}
            error={!!formErrors.password}
            helperText={formErrors.password}
            required
            InputProps={{
              // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            variant="outlined"
            type={showSecondPassword ? 'text' : 'password'}
            label="Confirm Password"
            name="confirm_password"
            value={confirm_password || ''}
            onChange={handleChange}
            margin="normal"
            error={!!formErrors.confirm_password}
            helperText={formErrors.confirm_password}
            required
            InputProps={{
              // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowSecondPassword}
                  >
                    {showSecondPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <div style={{ display: 'flex', marginTop: 50, justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          color="default"
          onClick={handleBack}
          style={{ marginRight: 10 }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          disabled={!isValid}
          color="primary"
          onClick={isValid ? handleNext : null}
        >
          Next
        </Button>
      </div>
    </Fragment>
  );
};

export default Thired;
