import { ADD_EXPENSE, DELETE_EXPENSE, GET_EXPENSE_LIST, GET_EXPENSE_REPORT, UPDATE_EXPENSE } from "../actions/ExpenseActions";

const initialState = {
  expenseList: [],
  expenseReport: [],
};

const ExpenseReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_EXPENSE_LIST: {
      return { ...state, expenseList: action.payload };
    }
    case ADD_EXPENSE: {
      return { ...state, };
    }
    case UPDATE_EXPENSE: {
      return { ...state, };
    }
    case DELETE_EXPENSE: {
      return { ...state, };
    }
    case GET_EXPENSE_REPORT: {
      return { ...state, expenseReport: action.payload };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default ExpenseReducer;
